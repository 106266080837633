/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  RESET: '/forgot-password',
  CHANGE: '/reset',
  VERIFY: '/verify',
  UNAUTHENTICATED: '/unauthenticated',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/user-management',
  EXPORT_USERS: '/export-users',
  SUBSCRIBER_MANAGEMENT: '/subscriber-management',
  MODERATION: '/moderation'
};

/*  Modules */
export const MODULES = {
  MODERATION: 'Moderation'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = [
  { name: 'SUPER_ADMIN', value: 'SUPER_ADMIN', priority: 0 },
  { name: 'ADMIN', value: 'ADMIN', priority: 1 },
  { name: 'AUTHOR', value: 'AUTHOR', priority: 2 },
  { name: 'MODERATOR', value: 'MODERATOR', priority: 3 },
  { name: 'USER', value: 'USER', priority: 4 }
];

export const PLAN = [
  { name: 'FREE', value: 'FREE' },
  { name: 'PREMIUM', value: 'PREMIUM' }
];

export const KEY_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  AUTHOR: 'AUTHOR',
  MODERATOR: 'MODERATOR',
  USER: 'USER'
};

export const ADMIN_ROLES = ['SUPER_ADMIN', 'ADMIN'];

export const ACTIONS = {
  ISHIGHLIGHTED: 'isHighlighted',
  ISACCEPTED: 'isAccepted',
  ISHIDDEN: 'isHidden'
};

export const VIDEO_STATUS = {
  LIVE: 'LIVE',
  DISCONNECTED: 'DISCONNECTED',
  RECORDING: 'RECORDING'
};

export const SCROLL_PERCENT = 0.85;
export const LIMIT = 10;
export const PERCENT_CONSTANT = 50;

/* Date and time */
export const POST_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT = 'MM-DD-YYYY hh:mm A';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const TIME_FORMAT = 'hh:mm A';
export const IMAGE = 'https://via.placeholder.com/600x512.png';
export const VIDEO_URL =
  'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8';
export const GIPHY_URL = 'https://media1.giphy.com/media';
export const APPLE_PROVIDER = 'apple.com';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const MODERATION_TAGS = {
  FIRST: '#F5222D',
  SECOND: '#FC8D17',
  THIRD: '#DAAC52',
  FOURTH: '#595959'
};

export const SORT_ORDER = [
  { name: 'Lowest', value: 'ASC' },
  { name: 'Highest', value: 'DESC' }
];
export const SORT_COMMENTS_BY_OPTIONS = [
  { name: 'Most Flagged', value: 'flagsCount_DESC' },
  { name: 'Moderation Score', value: 'moderationScore_DESC' },
  { name: 'Newest First', value: 'createdAt_DESC' },
  { name: 'Oldest First', value: 'createdAt_ASC' }
];

export const SORT_POSTS_BY_OPTIONS = [
  { name: 'likes', value: 'likesCount' },
  { name: 'comments', value: 'commentsCount' },
  { name: 'plays', value: 'playsCount' },
  { name: 'createdAt', value: 'createdAt' }
];

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200,
  laptop: 1440
};

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png'];
export const FILE_SIZE_20MB = 20971520;

export const GOOGLE = 'Google';
export const APPLE = 'Apple';

export const PRIVATE_CHANNEL = 'private-';
export const EXPORT_MESSAGE =
  'Users data export job has started! Please download it from the Export Users tabs after some time.';

export const PUSHER_EVENTS = {
  POST_EVENT: `${PRIVATE_CHANNEL}POST_EVENT`,
  COMMENT_MODERATION_EVENT: `${PRIVATE_CHANNEL}COMMENT_MODERATION_EVENT`
};
