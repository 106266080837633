import {
  CheckOutlined,
  EyeInvisibleOutlined,
  HighlightOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { ACTIONS } from '../../../../common/constants';

const CommonModerationButton = ({ handleActionButtons, comment }) => {
  return (
    <>
      <Button
        onClick={(e) => handleActionButtons(e, comment, ACTIONS.ISHIGHLIGHTED)}
        icon={<HighlightOutlined />}
        className={`${comment?.isHighlighted && 'moderation-action-btn'}`}
      >
        Highlight
      </Button>
      <Button
        onClick={(e) => handleActionButtons(e, comment, ACTIONS.ISHIDDEN)}
        icon={<EyeInvisibleOutlined />}
        className={`${
          comment?.isHidden ? 'moderation-action-btn mt-10' : 'mt-10'
        }`}
      >
        Hidden
      </Button>
      <Button
        onClick={(e) => handleActionButtons(e, comment, ACTIONS.ISACCEPTED)}
        icon={<CheckOutlined />}
        className={`${
          comment?.isAccepted ? 'moderation-action-btn mt-10' : 'mt-10'
        }`}
      >
        Accept
      </Button>
    </>
  );
};

export default CommonModerationButton;
