import {
  ExclamationCircleOutlined,
  FlagOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import confirm from 'antd/lib/modal/confirm';
import { cloneDeep, get, map, truncate } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { socialClient } from '../../../../apollo';
import {
  ACTIONS,
  GIPHY_URL,
  PERCENT_CONSTANT
} from '../../../../common/constants';
import { convertToPercentage } from '../../../../common/utils';
import CommonCard from '../../../../components/CommonCard';
import LikeCommentAsAuthor from '../../../../components/LikeCommentAsAuthor';
import StarCommentAsAuthor from '../../../../components/StarCommentAsAuthor';
import RenderModerationTags from '../../component/RenderModerationTags';
import { UPDATE_COMMENT } from '../graphql/Mutation';
import CommonModerationButton from './CommonModerationButtons';

const CommentCard = (props) => {
  const {
    comment,
    setComments,
    comments,
    selectedComment,
    setSelectedComment
  } = props;
  const filterModeration = comment?.moderationResponse?.classes?.filter(
    (response) => convertToPercentage(response?.score) >= PERCENT_CONSTANT
  );

  const [updateComment] = useMutation(UPDATE_COMMENT, {
    client: socialClient,
    onError() {}
  });

  const handleActionButtons = (e, data, fieldName) => {
    e.stopPropagation();
    let value;
    let title;
    let okText;
    let okType;
    let variables = { where: { id: data?.id } };
    if (fieldName === ACTIONS.ISHIGHLIGHTED) {
      value = get(data, ACTIONS.ISHIGHLIGHTED);
      title = `Are you sure, you want to ${
        value ? 'unhighlight' : 'highlight'
      } this comment?`;
      okText = value ? 'Unhighlight' : 'Highlight';
      okType = value ? 'danger' : 'primary';
      variables = { ...variables, data: { isHighlighted: !value } };
    } else if (fieldName === ACTIONS.ISHIDDEN) {
      value = get(data, ACTIONS.ISHIDDEN);
      title = `Are you sure, you want to ${
        value ? 'unhide' : 'hide'
      } this comment?`;
      okText = value ? 'Unhide' : 'Hide';
      okType = value ? 'primary' : 'danger';
      variables = { ...variables, data: { isHidden: !value } };
    } else {
      value = get(data, ACTIONS.ISACCEPTED);
      title = `Are you sure, you want to ${
        value ? 'unaccept' : 'accept'
      } this comment ? `;
      okText = value ? 'Unaccept' : 'Accept';
      okType = value ? 'danger' : 'primary';
      variables = { ...variables, data: { isAccepted: !value } };
    }
    confirm({
      title,
      okText,
      okType,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      className: 'action-buttons-modal',
      onOk: async () => {
        return new Promise((resolve) => {
          updateComment({
            variables
          }).then((res) => {
            const newComments = cloneDeep(comments);
            const id = newComments?.findIndex(
              (post) =>
                post?.id === get(res, 'data.updateCommentModeration.data.id')
            );
            if (id !== -1) {
              if (fieldName === ACTIONS.ISHIGHLIGHTED) {
                newComments[id].isHighlighted = !value;
              } else if (fieldName === ACTIONS.ISHIDDEN) {
                newComments[id].isHidden = !value;
              } else if (fieldName === ACTIONS.ISACCEPTED) {
                newComments[id].isAccepted = !value;
              }
            }
            setComments(newComments);
            resolve();
          });
        });
      }
    });
  };

  return (
    <div>
      <CommonCard
        className={`${
          selectedComment && selectedComment?.id === comment?.id
            ? 'selected-post mb-10 mr-10 pointer'
            : 'mb-10 mr-10 pointer'
        }`}
        onClick={() => {
          setSelectedComment(comment);
        }}
      >
        <div className="d-flex justify-between comment">
          <div className="user-section d-flex flex-vertical">
            <div>
              {comment?.comment && (
                <p className="comment-text">{comment?.comment}</p>
              )}
              {filterModeration?.length > 0 && (
                <div className="d-flex justify-between moderation-tag-wrapper">
                  {map(filterModeration, (response) => (
                    <RenderModerationTags
                      key={response?.class}
                      data={response}
                    />
                  ))}
                </div>
              )}
              {comment?.mediaUrl && (
                <img
                  src={comment?.mediaUrl}
                  alt="comment-giphy"
                  className="comment-media"
                />
              )}
              {comment?.giphyId && !comment?.mediaUrl && (
                <div className="comment-media-wrapper">
                  <img
                    src={`${GIPHY_URL}/${comment?.giphyId}/200.gif`}
                    alt={comment?.giphyId}
                    className="comment-media"
                  />
                </div>
              )}
            </div>
            <div className="user-details mt-auto d-flex align-center">
              {comment?.createdBy && (
                <>
                  <Avatar
                    size="small"
                    src={comment?.createdBy?.profileImage || ''}
                    className="mr-10"
                  >
                    {comment?.createdBy?.name?.charAt(0) || ''}
                  </Avatar>
                  <h4 className="mr-5 font-700 mb-0">
                    <Tooltip title={comment?.createdBy?.name} placement="top">
                      {truncate(comment?.createdBy?.name || '', { length: 17 })}
                    </Tooltip>
                  </h4>
                </>
              )}
              {comment?.createdAt && (
                <span className="mr-10">
                  <Tooltip
                    title={`${moment(comment?.createdAt)
                      .tz('America/Los_Angeles')
                      .format('Do MMMM, hh:mm A')} PST`}
                  >
                    {moment(comment?.createdAt)
                      .tz('America/Los_Angeles')
                      .fromNow()}
                  </Tooltip>
                </span>
              )}
              <span className="mr-5">
                <FlagOutlined />
                <span className="mr-5">{comment?.flagsCount}</span>
              </span>
              <span className="mr-5">
                <LikeCommentAsAuthor comment={comment} />
                <span className="mr-5">{comment?.likesCount}</span>
              </span>
              <span className="mr-5">
                <StarCommentAsAuthor comment={comment} />
                <span className="mr-5"> {comment?.starsCount} </span>
              </span>
              <span className="mr-5">
                <CopyToClipboard
                  // eslint-disable-next-line no-undef
                  text={`${window?.location?.href}/${comment?.id}`}
                >
                  <Tooltip
                    title="Copied!"
                    trigger="click"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <Tooltip title="Click to copy!" placement="bottom">
                      <LinkOutlined className="mr-5 pointer" />
                      <span className="pointer">Copy Link </span>
                    </Tooltip>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          </div>
          <div className="moderation-buttons d-flex flex-vertical justify-center width-percent-25">
            <CommonModerationButton
              handleActionButtons={handleActionButtons}
              comment={comment}
            />
          </div>
        </div>
      </CommonCard>
    </div>
  );
};

export default CommentCard;
