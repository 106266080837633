import { Tag } from 'antd';
import React from 'react';
import { MODERATION_TAGS } from '../../../common/constants';
import { convertToPercentage } from '../../../common/utils';

const RenderModerationTags = (props) => {
  const {
    data: { score, class: tagText }
  } = props;

  const displayText = `${convertToPercentage(score)}% ${tagText}`;

  if (score * 100 > 90) {
    return (
      <div>
        <Tag color={MODERATION_TAGS.FIRST}>{displayText}</Tag>
      </div>
    );
  }
  if (score * 100 > 80) {
    return (
      <div>
        <Tag color={MODERATION_TAGS.SECOND}>{displayText}</Tag>
      </div>
    );
  }
  if (score * 100 > 70) {
    return (
      <div>
        <Tag color={MODERATION_TAGS.THIRD}>{displayText}</Tag>
      </div>
    );
  }
  return (
    <div>
      <Tag color={MODERATION_TAGS.FOURTH}>{displayText}</Tag>
    </div>
  );
};

export default RenderModerationTags;
