import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Users from './Users';

const UsersWrapper = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES.USER_MANAGEMENT} component={Users} />
      </Switch>
    </>
  );
};

export default UsersWrapper;
