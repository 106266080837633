import { Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants';

const ModerationHeader = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [activeKey, setActiveKey] = useState('comments');

  const onMenuSelect = (e) => {
    history.push(e.key);
  };

  useEffect(() => {
    if (pathname?.includes('comments')) {
      setActiveKey('comments');
    }
    if (pathname?.includes('live')) {
      setActiveKey('live');
    }
    if (pathname?.includes('posts')) {
      setActiveKey('posts');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const items = [
    { label: 'Comments', key: `${ROUTES.MODERATION}/comments` },
    { label: 'Live', key: `${ROUTES.MODERATION}/live` },
    { label: 'Posts', key: `${ROUTES.MODERATION}/posts` }
  ];
  return (
    <Header>
      <Menu
        className="moderationMenu"
        mode="horizontal"
        activeKey={activeKey}
        selectedKeys={[`/${pathname.split('/')[1]}/${pathname.split('/')[2]}`]}
        defaultSelectedKeys={[`${ROUTES.MODERATION}/comments`]}
        onSelect={onMenuSelect}
        items={items}
      />
    </Header>
  );
};

export default ModerationHeader;
