import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import moment from 'moment';
import React from 'react';
import CommonCard from '../../../../components/CommonCard';
// eslint-disable-next-line import/no-cycle
import Post from './Post';

const ReferenceData = (props) => {
  const { type, post } = props;
  let image = null;
  let title = null;
  let creater = null;
  let createdAt = null;
  if (post) {
    switch (type) {
      case 'VIDEO':
        image = post?.image;
        title = post?.text;
        creater = post?.author?.name;
        createdAt = post?.createdAt;
        break;
      case 'EPISODE':
        image = post?.image;
        title = post?.text;
        creater = post?.author?.name;
        createdAt = post?.createdAt;
        break;
      case 'NEWS_ARTICLE':
        image = post?.image?.url;
        title = post?.text;
        creater = post?.author?.name;
        createdAt = post?.date;
        break;
      default:
        break;
    }
  }
  if (!post) {
    return '';
  }
  if (type === 'POST') {
    return <Post key={post?.id} post={post} shared />;
  }
  return (
    <div className="mr-10 mb-10">
      <CommonCard>
        {type === 'POST' && (
          <>
            <div className="d-flex align-center">
              <Avatar
                src={post?.author?.profileImage}
                icon={<UserOutlined />}
                className="mr-10"
              />
              <div>
                <h4 className="mb-0 font-700">{creater}</h4>
                <p className="mb-0">
                  {moment(createdAt).tz('America/Los_Angeles').fromNow()}
                </p>
              </div>
            </div>
            <div className="mt-14">
              <p>{title}</p>
            </div>
          </>
        )}
        {image && (
          <div className="image-container">
            <img src={image} alt={title} />
          </div>
        )}
      </CommonCard>
    </div>
  );
};

export default ReferenceData;
