import { Col, Divider, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ADMIN_ROLES } from '../../../common/constants';
import AddCommentAsAuthor from '../../../components/AddCommentAsAuthor';
import CommonCard from '../../../components/CommonCard';
import CommentList from './components/CommentList';
import ParentComment from './components/ParentComment';
import Post from './components/Post';
import SideCommentList from './components/SideCommentList';

const Comments = () => {
  const { state } = useContext(AppContext);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [commentCount, setCommentCount] = useState(0);
  return (
    <div>
      <div className="moderation-filters justify-between align-center">
        <div id="search-component" />
        <div className="d-flex">
          <div id="filter-switch" className="d-flex" />
          <div className="d-flex mr-10">
            <div id="range-picker" />
            <div id="sorting-select" />
          </div>
        </div>
      </div>
      <Divider className="m-0" />
      <Row gutter={12} className="moderation-content">
        <Col span={15}>
          <CommentList
            isFilterApplied={isFilterApplied}
            setIsFilterApplied={setIsFilterApplied}
            selectedComment={selectedComment}
            setSelectedComment={setSelectedComment}
          />
        </Col>
        <Col span={9}>
          <CommonCard className="side-card position-relative">
            {selectedComment && (
              <>
                <div
                  className={
                    ADMIN_ROLES?.includes(state?.currentUser?.role)
                      ? 'side-add-comment-card'
                      : 'side-comment-card'
                  }
                >
                  <Post selectedComment={selectedComment} />
                  <Divider className="divider" />
                  <ParentComment
                    selectedComment={selectedComment}
                    commentCount={commentCount}
                    setCommentCount={setCommentCount}
                  />
                  <Divider className="divider" />
                  <SideCommentList
                    selectedComment={selectedComment}
                    setCommentCount={setCommentCount}
                  />
                </div>
                {ADMIN_ROLES?.includes(state?.currentUser?.role) && (
                  <div className="add-comment mb-10">
                    <AddCommentAsAuthor
                      postId={selectedComment?.postId}
                      isComment
                    />
                  </div>
                )}
              </>
            )}
          </CommonCard>
        </Col>
      </Row>
    </div>
  );
};

export default Comments;
