import {
  ControlOutlined,
  FlagOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LinkOutlined,
  MessageOutlined,
  RollbackOutlined,
  StarFilled
} from '@ant-design/icons';
import { Divider, Tooltip, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { truncate } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AppContext } from '../../../../AppContext';
import { ADMIN_ROLES, GIPHY_URL } from '../../../../common/constants';
import { capitalizeWord } from '../../../../common/utils';
import AddCommentAsAuthor from '../../../../components/AddCommentAsAuthor';
import CommonCard from '../../../../components/CommonCard';
import LikeCommentAsAuthor from '../../../../components/LikeCommentAsAuthor';
import StarCommentAsAuthor from '../../../../components/StarCommentAsAuthor';
import CommonModerationButton from './CommonModerationButtons';

const { Paragraph } = Typography;
const SideCommentCard = ({
  isParent,
  showActions,
  handleShowAction,
  comment,
  handleActionButtons,
  commentCount
}) => {
  const { state } = useContext(AppContext);
  const [showAddReply, setShowAddReply] = useState(false);
  const handleShowAddReply = () => {
    setShowAddReply(!showAddReply);
  };
  return (
    <div>
      {comment && (
        <CommonCard
          className={`${
            isParent
              ? 'parent comment-card mt-10 mr-10'
              : 'comment-card mt-10 mr-10'
          }`}
        >
          <div className="d-flex justify-between">
            <div className="d-flex align-center">
              {comment?.createdBy && (
                <Avatar
                  src={comment?.createdBy?.profileImage ?? ''}
                  className="mr-5"
                >
                  {comment?.createdBy?.name?.charAt(0) ?? ''}
                </Avatar>
              )}
              {comment?.createdBy && (
                <h4 className="mb-0 mr-5 font-700">
                  <Tooltip title={comment?.createdBy?.name} placement="top">
                    {truncate(comment?.createdBy?.name || '', { length: 17 })}
                  </Tooltip>
                </h4>
              )}
              <span>
                {comment?.createdAt && (
                  <Tooltip
                    title={`${moment(comment?.createdAt)
                      .tz('America/Los_Angeles')
                      .format('Do MMMM, hh:mm A')} PST`}
                  >
                    {moment(comment?.createdAt)
                      .tz('America/Los_Angeles')
                      .fromNow()}
                  </Tooltip>
                )}
              </span>
            </div>
            <div className="d-flex align-center">
              <span className="mr-10">
                <ControlOutlined />{' '}
                {comment?.moderationScore &&
                  (comment?.moderationScore * 100).toFixed(2)}
                %
              </span>
              <span className="mr-10">
                <FlagOutlined />
                {comment?.flagsCount}
              </span>
              {isParent && (
                <span className="pointer">
                  {showActions ? (
                    <FullscreenOutlined onClick={handleShowAction} />
                  ) : (
                    <FullscreenExitOutlined onClick={handleShowAction} />
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="mt-10">
            <Paragraph>{comment?.comment}</Paragraph>
            {comment?.mediaUrl && (
              <img
                src={comment?.mediaUrl}
                alt={comment?.mediaUrl}
                className="comment-media"
              />
            )}
            {comment?.giphyId && (
              <div>
                <img
                  src={`${GIPHY_URL}/${comment?.giphyId}/200.gif`}
                  alt={comment?.giphyId}
                  className="comment-media"
                />
              </div>
            )}
          </div>
          {comment?.stars?.length > 0 && (
            <div>
              <StarFilled className="star-color" />{' '}
              <span className="star-wrapper mr-15">
                Starred by {comment?.stars?.[0]?.name}
                {comment?.stars?.length > 1 &&
                  ` and ${comment?.starsCount - 1} other`}
              </span>
              {comment?.stars?.slice(0, 3)?.map((person) => (
                <Avatar
                  key={person?.id}
                  size="small"
                  src={person?.profileImage}
                  className="merged-avatar"
                >
                  {person?.name?.charAt(0)}
                </Avatar>
              ))}
            </div>
          )}
          {(comment?.replyCount > 0 || commentCount > 0) && (
            <div className="replay-count-wrapper">
              <MessageOutlined />{' '}
              <span>{commentCount || comment?.replyCount}</span>
            </div>
          )}
          <Divider className="divider" />
          <div className="d-flex justify-between">
            <div>
              <span className="mr-10">
                <LikeCommentAsAuthor comment={comment} /> {comment?.likesCount}
              </span>
              <span className="mr-10">
                <StarCommentAsAuthor comment={comment} /> {comment?.starsCount}
              </span>
              <span className="mr-10">
                <CopyToClipboard
                  // eslint-disable-next-line no-undef
                  text={`${window?.location?.href}/${
                    isParent ? comment?.id : comment?.parentId
                  }`}
                >
                  <Tooltip
                    title="Copied!"
                    trigger="click"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <Tooltip title="Click to copy!" placement="bottom">
                      <LinkOutlined className="mr-5 pointer" />
                      <span className="pointer">Copy Link </span>
                    </Tooltip>
                  </Tooltip>
                </CopyToClipboard>
              </span>
              {ADMIN_ROLES?.includes(state?.currentUser?.role) && (
                <span>
                  <RollbackOutlined />{' '}
                  <span className="pointer" onClick={handleShowAddReply}>
                    Add Reply
                  </span>
                </span>
              )}
            </div>
          </div>
          {showAddReply && ADMIN_ROLES?.includes(state?.currentUser?.role) && (
            <div className="mt-10">
              <AddCommentAsAuthor
                postId={comment?.postId}
                parentId={comment?.id}
                setShowAddReply={setShowAddReply}
              />
            </div>
          )}
          {showActions && (
            <div className="d-flex flex-horizontal justify-between mt-10">
              <ul>
                {comment?.activityLogs?.map((log) => (
                  <li className="activity-logs" key={log?.id}>{`${
                    log?.triggeredByPerson &&
                    capitalizeWord(log?.triggeredByPerson?.role)
                  } ${log?.triggeredByPerson?.name}
               ${log?.title && capitalizeWord(log?.title)} ${
                    log?.createdAt &&
                    moment(log?.createdAt)
                      .tz('America/Los_Angeles')
                      .format('MM/DD/YYYY h:mm a')
                  } `}</li>
                ))}
              </ul>
              <div className="d-flex flex-vertical width-percent-40 float-right">
                <CommonModerationButton
                  handleActionButtons={handleActionButtons}
                  comment={comment}
                />
              </div>
            </div>
          )}
        </CommonCard>
      )}
    </div>
  );
};

export default SideCommentCard;
