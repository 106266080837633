import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { LOGOUT } from './graphql/Mutations';

const Logout = () => {
  const { dispatch, state } = useContext(AppContext);
  const { authToken } = state;
  const { push } = useHistory();
  const [logout] = useMutation(LOGOUT, {
    client: authClient
  });
  async function loggingOut() {
    try {
      await logout();
    } catch (error) {
      return error;
    }
  }
  useEffect(() => {
    if (authToken) {
      dispatch({ type: 'LOGOUT' });
      loggingOut();
    }
    push(ROUTES.LOGIN);
    // eslint-disable-next-line
  }, [authToken]);
  return null;
};

export default Logout;
