/* eslint-disable no-undef */
import {
  EditOutlined,
  EyeInvisibleOutlined,
  FlagOutlined,
  LikeOutlined,
  LinkOutlined,
  MessageOutlined,
  PlayCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Divider, Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { truncate } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppContext } from '../../../../AppContext';
import { ADMIN_ROLES } from '../../../../common/constants';
import CommonCard from '../../../../components/CommonCard';
import VideoPlayerModal from '../../../../components/VideoPlayerModal';
// eslint-disable-next-line import/no-cycle
import ReferenceData from './ReferenceData';

const Post = (props) => {
  const { state } = useContext(AppContext);
  const {
    post,
    selectedPost,
    postForEdit,
    handleEditPost,
    handleShowPostComments,
    showPostHiddenConfirm,
    setEditPostData,
    setShowPostForm,
    shared
  } = props;
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const handleCancel = () => {
    setShowPlayerModal(false);
  };
  const handleModal = () => {
    setShowPlayerModal(true);
  };
  return (
    <div
      className={`${
        post?.id === selectedPost || post?.id === postForEdit
          ? 'mr-10 mb-10 selected-post border-radius-5'
          : 'mr-10 mb-10'
      }`}
    >
      <CommonCard
        onClick={() =>
          handleShowPostComments ? handleShowPostComments(post?.id) : null
        }
      >
        <div className="d-flex align-center">
          <Avatar
            src={post?.author?.profileImage}
            icon={<UserOutlined />}
            className="mr-10"
          />
          <div>
            <h4 className="mb-0 font-700">
              <Tooltip title={post?.author?.name} placement="top">
                {truncate(post?.author?.name || '', { length: 17 })}
              </Tooltip>
            </h4>
            <p className="mb-0">
              <Tooltip
                title={`${moment(post?.createdAt)
                  .tz('America/Los_Angeles')
                  .format('Do MMMM, hh:mm A')} PST`}
              >
                {moment(post?.createdAt).tz('America/Los_Angeles').fromNow()}
              </Tooltip>
            </p>
          </div>
        </div>
        <div className="mt-14">
          <p>{post?.text}</p>
        </div>
        {post?.video && (
          <div className="video-container">
            <VideoPlayerModal
              data={post}
              showModal={showPlayerModal}
              handleCancel={handleCancel}
            />
            <img src={post?.video?.videoGif} alt={post?.text} />
            {(post?.video?.videoGif || post?.video?.thumbnailUrl) && (
              <Button
                icon={<PlayCircleOutlined style={{ fontSize: '44px' }} />}
                shape="circle"
                className="post-play-button"
                size="large"
                onClick={handleModal}
              />
            )}
          </div>
        )}
        {post?.image && (
          <div className="image-container">
            <img src={post?.image} alt={post?.text} />
          </div>
        )}
        {post?.referenceType && (
          <ReferenceData
            type={post?.referenceType}
            post={post?.referencePost}
          />
        )}
        {!shared && (
          <>
            <Divider className="divider" />
            <div className="d-flex justify-between">
              <div>
                <LikeOutlined />{' '}
                <span className="mr-10"> {post?.likesCount}</span>
                <MessageOutlined />
                <span className="mr-10"> {post?.commentsCount}</span>
                <FlagOutlined />
                <span className="mr-5"> {post?.totalFlagsCount}</span>
              </div>
              <div>
                <CopyToClipboard text={`${window?.location?.href}/${post?.id}`}>
                  <Tooltip
                    title="Copied!"
                    trigger="click"
                    onClick={(e) => e?.stopPropagation()}
                  >
                    <Tooltip title="Click to copy!" placement="bottom">
                      <LinkOutlined className="mr-5 pointer" />
                      <span className="pointer">Copy Link </span>
                    </Tooltip>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
            <Divider className="divider" />
            <div className="d-flex justify-between align-center">
              <div>
                <PlayCircleOutlined />
                <span className="mr-10"> {post.playsCount}</span>
              </div>
              <div>
                {ADMIN_ROLES?.includes(state?.currentUser?.role) && (
                  <Button
                    icon={<EditOutlined />}
                    className="mr-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditPost(post?.id);
                      setEditPostData({ ...post });
                      setShowPostForm(true);
                    }}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  icon={<EyeInvisibleOutlined />}
                  className={post?.isDeleted ? 'moderation-action-btn' : ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    showPostHiddenConfirm(post?.id, post?.isDeleted);
                  }}
                >
                  Hidden
                </Button>
              </div>
            </div>
          </>
        )}
      </CommonCard>
    </div>
  );
};

export default Post;
