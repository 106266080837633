import { Card } from 'antd';
import React from 'react';
import UsersTable from './components/UsersTable';

const Users = () => {
  return (
    <Card className="m-12">
      <div className="d-flex align-center justify-between">
        <h1>User Management</h1>
        <div id="user-addEdit-btn" />
      </div>
      <hr />
      <UsersTable />
    </Card>
  );
};

export default Users;
