import { gql } from '@apollo/client';

const GET_PERSON_EXPORT_JOBS = gql`
  query personExportJobs($filter: PersonExportJobsFilter) {
    personExportJobs(filter: $filter) {
      count
      data {
        id
        status
        fileUrl
        filter
        createdAt
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_PERSON_EXPORT_JOBS };
