import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Posts from './Posts';

const PostWrapper = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.MODERATION}/posts`} component={Posts} />
      <Route exact path={`${ROUTES.MODERATION}/posts/:id`} component={Posts} />
    </Switch>
  );
};

export default PostWrapper;
