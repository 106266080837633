import { useQuery } from '@apollo/client';
import { Button, Col, DatePicker, Divider, Row, Select } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { socialClient } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import {
  ADMIN_ROLES,
  KEY_ROLES,
  LIMIT,
  POST_DATE_FORMAT,
  SORT_ORDER,
  SORT_POSTS_BY_OPTIONS
} from '../../../common/constants';
import AddCommentAsAuthor from '../../../components/AddCommentAsAuthor';
import CommonCard from '../../../components/CommonCard';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import CommentList from './components/CommentList';
import EditPost from './components/EditPost';
import PostList from './components/PostList';
import { GET_AUTHOR_LIST, LIST_POST_MODERATION } from './graphql/Queries';

const Posts = () => {
  const { state } = useContext(AppContext);
  const [postForEdit, setPostForEdit] = useState(null);
  const [selectedPost, _setSelectedPost] = useState(null);
  const [postSearch, setPostSearch] = useState(null);
  const [authorSearch, setAuthorSearch] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [editPostData, setEditPostData] = useState(null);
  const [editLoader, setEditLoader] = useState(true);
  const [showPostForm, setShowPostForm] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const selectPostRef = useRef(selectedPost);

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const { data: authorList } = useQuery(GET_AUTHOR_LIST, {
    client: socialClient,
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  const { id: postsId } = useParams();
  const [initialVariable, setInitialVariable] = useState({
    filter: {
      skip: 0,
      limit: LIMIT,
      authorId: authorSearch,
      searchTerm: postSearch,
      createdAt: {
        after: dateRange?.[0],
        before: dateRange?.[1]
      },
      ...(postsId && { id: postsId })
    },
    sort: {
      ...(filterValue && { sortOn: filterValue }),
      ...(sortOrder && { sortBy: sortOrder })
    }
  });

  useEffect(() => {
    setInitialVariable({
      filter: {
        skip: 0,
        limit: LIMIT,
        authorId: authorSearch,
        searchTerm: postSearch,
        createdAt: {
          after: dateRange?.[0],
          before: dateRange?.[1]
        },
        ...(postsId && { id: postsId })
      },
      sort: {
        ...(filterValue && { sortOn: filterValue }),
        ...(sortOrder && { sortBy: sortOrder })
      }
    });
  }, [postsId, authorSearch, postSearch, dateRange, filterValue, sortOrder]);

  const {
    data: postListData,
    loading: isPostLoading,
    fetchMore,
    refetch
  } = useQuery(LIST_POST_MODERATION, {
    variables: initialVariable,
    client: socialClient,
    fetchPolicy: 'network-only'
  });

  const setSelectedPost = (data) => {
    selectPostRef.current = data;
    _setSelectedPost(data);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  const handleShowPostComments = (id) => {
    setSelectedPost(id);
    setPostForEdit(null);
    setShowPostForm(false);
  };
  const handleEditPost = (data) => {
    setPostForEdit(data);
    setSelectedPost(null);
  };
  const handleAuthorChange = (author) => {
    setAuthorSearch(author);
  };
  const handleSearchChange = (text) => {
    setPostSearch(text);
  };
  const handleFilterChange = (value) => {
    if (!value) {
      setSortOrder(undefined);
    } else {
      setSortOrder('DESC');
    }
    setFilterValue(value);
  };
  const handleSortOrder = (order) => {
    setSortOrder(order);
  };
  const handleDateRange = (range) => {
    if (range?.length > 1) {
      const day1 = range?.[0]?.startOf('day');
      const day2 = range?.[1]?.isSame(new Date(), 'day')
        ? range?.[1]
        : range?.[1]?.endOf('day');
      setDateRange([day1, day2]);
    } else {
      setDateRange(null);
    }
  };

  const handleSetEditPostData = (data) => {
    setEditPostData(null);
    setEditLoader(true);
    setTimeout(() => {
      setEditLoader(false);
    }, 500);
    setEditPostData({ ...data });
  };

  const handleAdd = () => {
    setShowPostForm(true);
    setEditPostData(null);
    setEditLoader(true);
    setTimeout(() => {
      setEditLoader(false);
    }, 500);
  };

  return (
    <div>
      <div className="moderation-filters justify-between align-center">
        <SearchComponent
          className="search-component"
          getData={handleSearchChange}
        />
        <div className="d-flex">
          <div className="d-flex mr-10">
            <RangePicker
              onChange={handleDateRange}
              disabledDate={disabledDate}
              format={POST_DATE_FORMAT}
              className="mr-10"
            />
            <Select
              onChange={handleFilterChange}
              placeholder="Filter By"
              allowClear
              className="mr-10"
            >
              {SORT_POSTS_BY_OPTIONS?.map((option) => (
                <Option key={option?.name} value={option?.value}>
                  {option?.name}
                </Option>
              ))}
            </Select>
            <Select
              onChange={handleAuthorChange}
              placeholder="Select Author"
              allowClear
              className="mr-10"
            >
              {authorList?.authors?.data?.map((author) => (
                <Option key={author?.id} value={author?.id}>
                  {author?.name}
                </Option>
              ))}
            </Select>
            <Select
              onChange={handleSortOrder}
              value={filterValue ? sortOrder : undefined}
              placeholder="Sort By"
              allowClear
              className="mr-10"
            >
              {SORT_ORDER?.map((order) => (
                <Option key={order?.value} value={order?.value}>
                  {order?.name}
                </Option>
              ))}
            </Select>
            {(state?.currentUser?.role === KEY_ROLES?.ADMIN ||
              state?.currentUser?.role === KEY_ROLES?.SUPER_ADMIN) && (
              <Button id="btn-post-create" type="primary" onClick={handleAdd}>
                Create Post
              </Button>
            )}
          </div>
        </div>
      </div>
      <Divider className="m-0" />
      <Row gutter={12} className="moderation-content">
        <Col span={12}>
          <PostList
            postForEdit={postForEdit}
            handleEditPost={handleEditPost}
            setShowPostForm={setShowPostForm}
            selectedPost={selectedPost}
            handleShowPostComments={handleShowPostComments}
            setSelectedPost={setSelectedPost}
            authorSearch={authorSearch}
            postSearch={postSearch}
            dateRange={dateRange}
            filterValue={filterValue}
            sortOrder={sortOrder}
            fetchMorePosts={fetchMore}
            isPostLoading={isPostLoading}
            postListData={postListData}
            setEditPostData={handleSetEditPostData}
          />
        </Col>
        <Col span={12} className="post-comment-card">
          <CommonCard className="comment-card position-relative">
            {selectedPost && !showPostForm && (
              <div>
                <CommentList
                  selectedPost={selectedPost}
                  selectPostRef={selectPostRef}
                  setShowReplies={setShowReplies}
                />
                {ADMIN_ROLES?.includes(state?.currentUser?.role) &&
                  !showReplies && (
                    <div className="add-comment mb-10">
                      <AddCommentAsAuthor postId={selectedPost} isComment />
                    </div>
                  )}
              </div>
            )}
            {showPostForm &&
              (editLoader ? (
                <LoaderComponent />
              ) : (
                <EditPost
                  editLoader={editLoader}
                  isPostLoading={isPostLoading}
                  editData={{ ...editPostData }}
                  isUpdate={!!editPostData}
                  authorList={authorList}
                  handleShowPostComments={handleShowPostComments}
                  refetchPostData={refetch}
                />
              ))}
          </CommonCard>
        </Col>
      </Row>
    </div>
  );
};

export default Posts;
