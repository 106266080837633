import { AppleFilled, GoogleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import logo from '../../assets/images/loginLogo.png';
import { APPLE_PROVIDER, ROUTES } from '../../common/constants';
import './auth.less';
import { auth } from './Firebase';
import { VALIDATE_TOKEN } from './graphql/Queries';

const Login = () => {
  const { initializeAuth } = useContext(AppContext);
  const history = useHistory();
  const [loginSocialUser] = useLazyQuery(VALIDATE_TOKEN, {
    client: authClient,
    fetchPolicy: 'network-only'
  });
  const successCallback = (accessToken, refreshToken) => {
    initializeAuth(accessToken, refreshToken);
    history.push(ROUTES.MAIN);
  };
  const provider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    provider?.setCustomParameters({
      prompt: 'select_account'
    });
    await signInWithPopup(auth, provider)
      .then(() => {
        auth.currentUser.getIdToken().then(function (idToken) {
          loginSocialUser({
            variables: {
              data: { token: `Bearer ${idToken}`, checkRole: true }
            }
          })
            .then((res) => {
              const { accessToken, refreshToken } = res?.data?.validateToken;
              if (successCallback) {
                successCallback(accessToken, refreshToken);
              }
            })
            .catch(() => {});
        });
      })
      .catch(() => {});
  };
  const appleProvider = new OAuthProvider(APPLE_PROVIDER);
  const signInWithApple = async () => {
    await signInWithPopup(auth, appleProvider)
      .then(() => {
        auth.currentUser.getIdToken().then(function (idToken) {
          loginSocialUser({
            variables: {
              data: { token: `Bearer ${idToken}`, checkRole: true }
            }
          })
            .then((res) => {
              const { accessToken, user, refreshToken } =
                res?.data?.validateToken;
              if (successCallback) {
                successCallback(accessToken, user, refreshToken);
              }
            })
            .catch(() => {});
        });
      })
      .catch(() => {});
  };
  return (
    <div className="login-wrapper">
      <div className="login-form-wrapper">
        <div className="header d-flex justify-center">
          <img src={logo} alt="Big_Time_Rush" title="Big_Time_Rush" />
        </div>
        <hr />
        <div className="body login-form">
          <Button
            type="primary"
            className="google-button"
            onClick={signInWithGoogle}
            icon={<GoogleOutlined />}
          >
            Sign in with Google
          </Button>
          <Button
            className="apple-button"
            onClick={signInWithApple}
            icon={<AppleFilled />}
          >
            Sign in with Apple
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
