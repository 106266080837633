import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, DatePicker, Form, Input, Select, Tooltip, Upload } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import { nth } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import client, { toast } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import {
  ALLOW_IMAGE_TYPES,
  DATE_FORMAT,
  FILE_SIZE_20MB,
  KEY_ROLES,
  PLAN,
  ROLES,
  TIME_FORMAT
} from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import { UPDATE_PERSON } from '../graphql/Mutations';
import { GET_PROFILE_IMAGE_SIGNED_URL } from '../graphql/Queries';

const { Option } = Select;
const { required } = formValidatorRules;
const AddEditUserModal = (props) => {
  const { isUpdate, showModal, setShowModal, userData, refetchUserData } =
    props;
  const [form] = Form.useForm();
  const { state, getLoggedInUser, dispatch } = useContext(AppContext);
  const loggedInUser = getLoggedInUser();
  const [fileList, setFileList] = useState([]);
  const [expireTitle, setExpireTitle] = useState('');
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [updatePerson, { loading }] = useMutation(UPDATE_PERSON, {
    fetchPolicy: 'network-only',
    onError() {}
  });

  const handleCancel = () => {
    setShowModal(false);
    form?.resetFields();
  };
  const disabledStartDate = (startValue) => {
    return startValue?.valueOf() < moment().valueOf();
  };
  const handleScheduleChange = (currentTime) => {
    if (currentTime) {
      const laTime = moment(currentTime?.valueOf())?.tz('America/Los_Angeles');
      setExpireTitle(
        `Expire at: ${laTime?.format('lll')} ${laTime?.zoneAbbr()}`
      );
      setTooltipIsVisible(true);
    } else {
      setExpireTitle('');
      setTooltipIsVisible(false);
    }
  };
  const onUserSubmitFinish = async (formValues) => {
    const {
      name,
      role,
      username,
      profileImage,
      promoSubscriptionPlan,
      promoSubscriptionPlanExpiration
    } = formValues;
    try {
      if (isUpdate) {
        let imageUrl = null;
        if (profileImage?.fileList?.length > 0) {
          const fileName = profileImage?.fileList?.[0]?.name;
          const getSignedPutUrlResult = await client.query({
            query: GET_PROFILE_IMAGE_SIGNED_URL,
            variables: { data: { fileName, personId: userData?.id } },
            fetchPolicy: 'network-only'
          });
          if (getSignedPutUrlResult?.data?.getPersonProfileImageSignedPutUrl) {
            await fileUpload(
              getSignedPutUrlResult?.data?.getPersonProfileImageSignedPutUrl
                ?.signedUrl,
              profileImage?.fileList?.[0]?.originFileObj
            );
            imageUrl =
              getSignedPutUrlResult?.data?.getPersonProfileImageSignedPutUrl
                ?.key;
          }
        }
        const response = await updatePerson({
          variables: {
            name,
            role,
            username,
            promoSubscriptionPlan,
            promoSubscriptionPlanExpiration: promoSubscriptionPlanExpiration
              ? promoSubscriptionPlanExpiration?.toISOString()
              : null,
            personId: userData?.id,
            ...(imageUrl && { profileImage: imageUrl })
          }
        });
        if (response?.data?.updatePerson) {
          form.resetFields();
          setShowModal(false);
          if (
            state?.currentUser?.id === response?.data?.updatePerson?.data?.id
          ) {
            dispatch({
              type: 'SET_CURRENT_USER',
              data: response?.data?.updatePerson?.data
            });
          }
          refetchUserData();
        }
        return response;
      }
    } catch (error) {
      return error;
    }
  };
  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };
  const initialValues = {
    ...userData,
    promoSubscriptionPlanExpiration: userData?.promoSubscriptionPlanExpiration
      ? moment(userData?.promoSubscriptionPlanExpiration)
      : null,
    profileImage: null
  };

  const checkPriority = (value) => {
    const selectedRole = ROLES.filter((role) => role.name === value);
    const prevRole = ROLES.filter((role) => role.name === userData?.role);
    if (selectedRole?.[0]?.priority > prevRole?.[0]?.priority) {
      toast({
        message: 'You cannot downgrade the role!',
        type: 'error'
      });
      form.setFieldsValue({
        role: userData?.role
      });
    } else {
      form.setFieldsValue({
        role: userData?.role
      });
      confirm({
        centered: true,
        title:
          'Are you sure you want to change the role, after changing you can not downgrade the role? ',
        onOk: () =>
          form.setFieldsValue({
            role: value
          }),
        onCancel: () =>
          form.setFieldsValue({
            role: userData?.role
          })
      });
    }
  };

  return (
    <Modal
      title={isUpdate ? 'Update User' : 'Add New User'}
      destroyOnClose
      visible={showModal}
      onOk={handleAdd}
      okText={isUpdate ? 'Save' : 'Add User'}
      onCancel={handleCancel}
      confirmLoading={loading}
      centered
    >
      <Form
        form={form}
        initialValues={initialValues || { isActive: true }}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item
          rules={[{ required, message: 'Please enter the fullname!' }]}
          name="name"
          label="Full Name"
        >
          <Input />
        </Form.Item>
        <Form.Item name="username" label="User Name">
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required, message: 'Please select the role!' }]}
          name="role"
          label="Role"
        >
          <Select
            placeholder="Select Role"
            showSearch
            allowClear
            optionFilterProp="children"
            onChange={(e) => {
              checkPriority(e);
            }}
            disabled={
              userData?.role === KEY_ROLES.SUPER_ADMIN ||
              loggedInUser?.id === userData?.id
            }
          >
            {ROLES?.map((option) => (
              <Option key={option?.name} value={option?.value}>
                {option?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="promoSubscriptionPlan" label="Plan">
          <Select
            placeholder="Select Plan"
            showSearch
            optionFilterProp="children"
          >
            {PLAN?.map((option) => (
              <Option key={option?.name} value={option?.value}>
                {option?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Tooltip
          title={expireTitle}
          visible={tooltipIsVisible}
          placement="bottom"
          arrowPointAtCenter
        >
          <Form.Item
            name="promoSubscriptionPlanExpiration"
            label="Plan expiration date"
          >
            <DatePicker
              placeholder="Select Date/Time"
              format={DATE_FORMAT}
              showTime={{ use12Hours: true, format: TIME_FORMAT }}
              disabledDate={disabledStartDate}
              onChange={handleScheduleChange}
              onOk={handleScheduleChange}
            />
          </Form.Item>
        </Tooltip>
        <Form.Item name="profileImage" label="Profile Image">
          <Upload
            beforeUpload={() => {
              return false;
            }}
            maxCount={1}
            accept={ALLOW_IMAGE_TYPES}
            onChange={(info) => {
              const imageSize = info?.file?.size;
              const splitData = info?.file?.type?.split('/');
              const imageType = nth(splitData, 1);
              const isImage = ALLOW_IMAGE_TYPES.includes(imageType);
              if (!isImage) {
                toast({
                  message: 'You can only upload Image',
                  type: 'error'
                });
                setFileList([]);
              } else if (imageSize > FILE_SIZE_20MB) {
                toast({
                  message: 'You can only upload Image less than 20 MB',
                  type: 'error'
                });
                setFileList([]);
              } else {
                setFileList(info?.fileList);
              }
            }}
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditUserModal;
