import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Empty, Modal, PageHeader, Row, Spin } from 'antd';
import { cloneDeep, get, uniqBy } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { socialClient } from '../../../../apollo';
import { AppContext } from '../../../../AppContext';
import {
  ACTIONS,
  ADMIN_ROLES,
  PUSHER_EVENTS
} from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { usePusher } from '../../../../pusher';
import { UPDATE_COMMENT_MODERATION } from '../graphql/Mutations';
import { COMMENT_MODERATION } from '../graphql/Queries';
import Comment from './Comment';

const { confirm } = Modal;

const CommentList = (props) => {
  const { state } = useContext(AppContext);
  const { selectedPost, selectPostRef, setShowReplies } = props;
  const pusher = usePusher();
  const [comments, _setComments] = useState([]);
  const [showCommentReply, _setShowCommentReply] = useState(null);
  const [commentReplyParent, setCommentReplyParent] = useState([]);
  const [commentReplyLevel, setCommentReplyLevel] = useState(0);
  const virtuoso = useRef(null);
  const commentsRef = useRef(comments);
  const showCommentReplyRef = useRef(showCommentReply);
  const [initialVariable] = useState({
    skip: 0,
    limit: 10
  });

  const [updateCommentModeration] = useMutation(UPDATE_COMMENT_MODERATION, {
    client: socialClient
  });
  const setComments = (data) => {
    commentsRef.current = data;
    _setComments(data);
  };
  const setShowCommentReply = (data) => {
    showCommentReplyRef.current = data;
    _setShowCommentReply(data);
  };
  useEffect(() => {
    setShowCommentReply(null);
  }, [selectedPost]);
  const commentUpdateHandler = async (data) => {
    if (data?.comment?.node) {
      const id = commentsRef?.current?.findIndex(
        (comment) => comment?.id === data?.comment?.node?.id
      );

      if (id !== -1) {
        const newComments = cloneDeep(commentsRef?.current);
        newComments[id] = {
          ...newComments?.[id],
          ...data?.comment?.node
        };
        setComments([...newComments]);
      }
    }
  };

  const commentCreateHandler = async (data) => {
    if (data?.comment?.node?.postId) {
      if (selectPostRef?.current === data?.comment?.node?.postId) {
        if (
          (!showCommentReplyRef.current &&
            !get(data, 'comment.node.parentId')) ||
          showCommentReplyRef.current === get(data, 'comment.node.parentId')
        ) {
          setComments([data?.comment?.node, ...commentsRef?.current]);
        }
        const id = commentsRef?.current?.findIndex((comment) => {
          if (get(data, 'comment.node.parentId')) {
            return comment?.id === get(data, 'comment.node.parentId');
          }
          return false;
        });
        if (id !== -1) {
          const newComments = cloneDeep(commentsRef.current);
          const newReplyCount = newComments[id]?.replies?.count + 1;
          const replies = get(commentsRef.current, 'replies');
          newComments[id] = {
            ...newComments[id],
            replies: { count: newReplyCount, ...replies }
          };
          setComments(newComments);
        }
      }
    }
  };

  useEffect(() => {
    const commentChannel = pusher.subscribe(
      PUSHER_EVENTS.COMMENT_MODERATION_EVENT
    );
    commentChannel.bind('UPDATED', commentUpdateHandler);
    commentChannel.bind('CREATED', commentCreateHandler);

    return () => {
      commentChannel.unbind('UPDATED', commentUpdateHandler);
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe(PUSHER_EVENTS.COMMENT_MODERATION_EVENT);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data,
    loading: isCommentLoading,
    fetchMore: fetchMoreComments
  } = useQuery(COMMENT_MODERATION, {
    variables: {
      filter: {
        skip: initialVariable?.skip,
        limit: initialVariable?.limit,
        postId: selectedPost,
        ...(showCommentReply && { parentId: showCommentReply })
      }
    },
    client: socialClient,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!isCommentLoading && data?.commentThreadModeration) {
      const commentList = data?.commentThreadModeration?.data;
      setComments(commentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentLoading]);

  const loadMoreComments = async () => {
    try {
      await fetchMoreComments({
        variables: {
          filter: {
            skip: comments?.length,
            limit: initialVariable?.limit,
            postId: selectedPost,
            ...(showCommentReply && { parentId: showCommentReply })
          }
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { commentThreadModeration: newCommentsData } =
              fetchMoreResult;
            setComments(uniqBy([...comments, ...newCommentsData?.data]), 'id');
          }
        }
      });
    } catch (error) {
      return error;
    }
  };

  const handleCommentReplyShow = (comment) => {
    if (comment?.replies?.count > 0) {
      const newCommentReplyParent = [...commentReplyParent, null];
      setCommentReplyParent(newCommentReplyParent);
      setShowCommentReply(comment?.id);
      setCommentReplyLevel(commentReplyLevel + 1);
      setShowReplies(true);
    }
  };

  const handlePreviousCommentReplyShow = () => {
    if (commentReplyLevel === 1) {
      setCommentReplyParent([]);
      setShowCommentReply(null);
    } else {
      const newCommentReplyParent = [...commentReplyParent];
      const parentId = newCommentReplyParent?.pop();
      setCommentReplyParent(newCommentReplyParent);
      setShowCommentReply(parentId);
    }
    setShowReplies(false);
    setCommentReplyLevel(commentReplyLevel - 1);
  };

  const handleActionButtons = (e, comment, actions) => {
    e.stopPropagation();
    let value;
    let title;
    let okText;
    let okType;
    let queryVariables = { where: { id: comment?.id } };

    switch (actions) {
      case ACTIONS.ISHIGHLIGHTED:
        value = get(comment, ACTIONS.ISHIGHLIGHTED);
        title = `Are you sure, you want to ${
          value ? 'unhighlight' : 'highlight'
        } this comment?`;
        okText = value ? 'Unhighlight' : 'Highlight';
        okType = value ? 'danger' : 'primary';
        queryVariables = { ...queryVariables, data: { isHighlighted: !value } };
        break;
      case ACTIONS.ISHIDDEN:
        value = get(comment, ACTIONS.ISHIDDEN);
        title = `Are you sure, you want to ${
          value ? 'unhide' : 'hide'
        } this comment?`;
        okText = value ? 'Unhide' : 'Hide';
        okType = value ? 'primary' : 'danger';
        queryVariables = { ...queryVariables, data: { isHidden: !value } };
        break;
      case ACTIONS.ISACCEPTED:
        value = get(comment, ACTIONS.ISACCEPTED);
        title = `Are you sure, you want to ${
          value ? 'unaccept' : 'accept'
        } this comment ? `;
        okText = value ? 'Unaccept' : 'Accept';
        okType = value ? 'danger' : 'primary';
        queryVariables = { ...queryVariables, data: { isAccepted: !value } };
        break;
      default:
        break;
    }

    confirm({
      title,
      okText,
      okType,
      className: 'danger-btn',
      async onOk() {
        try {
          const response = await updateCommentModeration({
            variables: queryVariables
          });
          if (response && get(response, 'data.updateCommentModeration')) {
            const newComments = cloneDeep(comments);
            const id = newComments?.findIndex(
              (post) =>
                post?.id ===
                get(response, 'data.updateCommentModeration.data.id')
            );
            if (id !== -1) {
              if (actions === ACTIONS.ISHIGHLIGHTED) {
                newComments[id].isHighlighted = !value;
              } else if (actions === ACTIONS.ISHIDDEN) {
                newComments[id].isHidden = !value;
              } else if (actions === ACTIONS.ISACCEPTED) {
                newComments[id].isAccepted = !value;
              }
            }
            setComments(newComments);
          }
        } catch (error) {
          return error;
        }
      }
    });
  };

  if (isCommentLoading) {
    return <LoaderComponent />;
  }
  if (comments?.length === 0) {
    return <Empty />;
  }
  return (
    <div
      className={
        // eslint-disable-next-line no-nested-ternary
        showCommentReply
          ? 'reply-virtuoso mt-10'
          : ADMIN_ROLES?.includes(state?.currentUser?.role)
          ? 'post-add-comment-virtuoso mt-10'
          : 'post-comment-virtuoso mt-10'
      }
    >
      {!isCommentLoading && showCommentReply && (
        <PageHeader
          onBack={() => handlePreviousCommentReplyShow()}
          title=" "
          className="reply-header"
        />
      )}
      {isCommentLoading && comments?.length === 0 ? (
        <LoaderComponent />
      ) : (
        <Virtuoso
          overscan={100}
          ref={virtuoso}
          data={comments}
          endReached={
            comments?.length < data?.commentThreadModeration?.count &&
            loadMoreComments
          }
          itemContent={(index, comment) => {
            if (!comment) {
              return null;
            }
            return (
              <Comment
                index={index}
                key={comment?.id}
                data={comment}
                selectedPost={selectedPost}
                handleCommentReplyShow={handleCommentReplyShow}
                handleActionButtons={handleActionButtons}
              />
            );
          }}
          footer={() => {
            if (isCommentLoading) {
              return (
                <Row className="d-flex justify-center">
                  <Col>
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 44 }} spin />
                      }
                    />
                  </Col>
                </Row>
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default CommentList;
