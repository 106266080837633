import { LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Col, DatePicker, Empty, Row, Select } from 'antd';
import { cloneDeep, map, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { socialClient } from '../../../../apollo';
import {
  LIMIT,
  POST_DATE_FORMAT,
  PUSHER_EVENTS
} from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import Portal from '../../../../components/Portal';
import { usePusher } from '../../../../pusher';
import {
  GET_AUTHOR_LIST,
  LIST_POST_MODERATION
} from '../../post/graphql/Queries';
import LivePost from './LivePost';

const LivePostList = (props) => {
  const { selectedPost, setSelectedPost, setSelectedPostData } = props;
  const { id: livePostId } = useParams();
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const [filterBy, setFilterBy] = useState(null);
  const [livePosts, setLivePosts] = useState([]);
  const [sortBy, setSortBy] = useState(undefined);

  const FilterByOptions = [
    { name: '# of comments', value: 'comments' },
    { name: '# of likes', value: 'likes' }
  ];

  const SortByOptions = [
    { name: 'Lowest', value: 'Lowest' },
    { name: 'Highest', value: 'Highest' }
  ];

  const filterByChange = (data) => {
    setLivePosts([]);
    if (!data) {
      setSortBy(undefined);
    } else {
      setSortBy('Highest');
    }
    setFilterBy(data);
  };

  const sortByChange = (data) => {
    setLivePosts([]);
    if (data) {
      setSortBy(data);
    } else {
      setFilterBy(undefined);
      setSortBy(undefined);
    }
  };

  const orderBy = (category, type) => {
    let sortOn = '';
    let sortByOrder = '';
    switch (category) {
      case 'comments':
        sortOn = 'commentsCount';
        break;
      case 'likes':
        sortOn = 'likesCount';
        break;
      default:
        break;
    }

    if (type === 'Highest') {
      sortByOrder = 'DESC';
    } else {
      sortByOrder = 'ASC';
    }

    return { sortOn, sortBy: sortByOrder };
  };

  const pusher = usePusher();
  const [isPostsEnd, setIsPostsEnd] = useState(false);
  const [skipRecord, setSkipRecord] = useState(0);
  const [createPusherData, setCreatePusherData] = useState({});
  const [updatePushData, setUpdatePushData] = useState({});
  const [dateRange, setDateRange] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [authorsData, setAuthorsData] = useState([]);
  const [isAuthorsEnd, setIsAuthorsEnd] = useState(false);
  const [authorSelected, setAuthorSelected] = useState(null);
  const virtuoso = useRef(null);

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const [fetchAuthors, { loading: authorsLoading }] = useLazyQuery(
    GET_AUTHOR_LIST,
    {
      client: socialClient,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT
        }
      },
      onError() {},
      onCompleted: (res) => {
        if (res?.authors?.data?.length < LIMIT) {
          setIsAuthorsEnd(true);
        }
        setAuthorsData(uniqBy([...authorsData, ...res?.authors?.data], 'id'));
      }
    }
  );

  const [fetchLivePosts, { loading }] = useLazyQuery(LIST_POST_MODERATION, {
    client: socialClient,
    variables: {
      filter: {
        isLive: true,
        skip: 0,
        limit: LIMIT,
        ...(livePostId && { id: livePostId }),
        ...(dateRange && {
          createdAt: {
            before: dateRange?.[1],
            after: dateRange?.[0]
          }
        }),
        ...(authorSelected && { authorId: authorSelected })
      },
      sort:
        !livePostId && filterBy
          ? orderBy(filterBy, sortBy)
          : {
              sortOn: 'createdAt',
              sortBy: 'DESC'
            }
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setSelectedPostData(res?.listPostModeration?.data?.[0]);
      setSelectedPost(res?.listPostModeration?.data?.[0]?.id);
      if (res?.listPostModeration?.data?.length < LIMIT) {
        setIsPostsEnd(true);
      } else {
        setIsPostsEnd(false);
      }
      setLivePosts([...livePosts, ...res?.listPostModeration?.data]);
    },
    onError() {}
  });

  useEffect(() => {
    fetchLivePosts();
    fetchAuthors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorSelected, filterBy]);

  const loadMorePosts = async () => {
    if (!isPostsEnd) {
      setSkipRecord((prevState) => prevState + 1);
      await fetchLivePosts({
        variables: {
          filter: {
            skip: (skipRecord + 1) * LIMIT,
            limit: LIMIT,
            isLive: true,
            ...(livePostId && { id: livePostId }),
            ...(dateRange && {
              createdAt: {
                before: dateRange?.[1],
                after: dateRange?.[0]
              }
            }),
            ...(authorSelected && { authorId: authorSelected })
          },
          sort: filterBy
            ? orderBy(filterBy, sortBy)
            : {
                sortOn: 'createdAt',
                sortBy: 'DESC'
              }
        }
      });
    }
  };

  const updateComments = useCallback(
    (data, pusherState) => {
      if (pusherState === 'create') {
        if (!isFilterApplied) {
          const tempComment = [...livePosts];
          setLivePosts([{ ...data }, ...tempComment]);
        }
      } else {
        const index = livePosts?.findIndex((post) => post?.id === data?.id);
        if (index !== -1) {
          const newPosts = cloneDeep(livePosts);
          const newCommentsCount = data?.commentsCount;
          const video = data?.video;
          newPosts[index] = {
            ...newPosts[index],
            commentsCount: newCommentsCount,
            video: {
              ...video
            }
          };
          setLivePosts(newPosts);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [livePosts, createPusherData, updatePushData]
  );

  const commentCreateHandler = (newData) => {
    if (newData?.post?.node) {
      const nodeData = newData?.post?.node;
      setCreatePusherData(nodeData);
    }
  };

  const newCommentCreateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData, 'create');
    }
  };

  const commentUpdateHandler = (newData) => {
    if (newData?.post?.node) {
      const nodeData = newData?.post?.node;
      setUpdatePushData(nodeData);
    }
  };

  const newCommentUpdateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData);
    }
  };

  useEffect(() => {
    if (pusher) {
      const commentChannel = pusher.subscribe(PUSHER_EVENTS.POST_EVENT);
      commentChannel.bind('CREATED', commentCreateHandler);
      commentChannel.bind('UPDATED', commentUpdateHandler);

      return () => {
        commentChannel.unbind('UPDATED', commentUpdateHandler);
        commentChannel.unbind('CREATED', commentCreateHandler);
        pusher.unsubscribe(PUSHER_EVENTS.POST_EVENT);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createPusherData?.id) {
      newCommentCreateHandler({ ...createPusherData });
      setCreatePusherData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPusherData, updateComments]);

  useEffect(() => {
    if (updatePushData?.id) {
      newCommentUpdateHandler({ ...updatePushData });
      setUpdatePushData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePushData, updateComments]);

  const handleRangeChange = (date) => {
    if (date && date.length > 1) {
      const day1 = date[0].startOf('day');
      const day2 = date[1].isSame(new Date(), 'day')
        ? date[1]
        : date[1].endOf('day');

      setDateRange([day1, day2]);
      setLivePosts([]);
      setIsFilterApplied(true);
      fetchLivePosts({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            createdAt: { before: day2, after: day1 },
            isLive: true,
            ...(authorSelected && { authorId: authorSelected })
          },
          sort: filterBy
            ? orderBy(filterBy, sortBy)
            : {
                sortOn: 'createdAt',
                sortBy: 'DESC'
              }
        }
      });
    } else {
      setLivePosts([]);
      setIsFilterApplied(false);
      setDateRange(null);
      fetchLivePosts({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            isLive: true,
            ...(authorSelected && { authorId: authorSelected })
          },
          sort: filterBy
            ? orderBy(filterBy, sortBy)
            : {
                sortOn: 'createdAt',
                sortBy: 'DESC'
              }
        }
      });
    }
  };

  const handleAuthorScroll = (event) => {
    const { target } = event;
    const scrolledToBottom =
      target?.scrollHeight - target?.scrollTop === target?.clientHeight;
    if (scrolledToBottom && !isAuthorsEnd) {
      fetchAuthors({
        variables: {
          filter: {
            skip: authorsData?.length,
            limit: LIMIT
          }
        }
      });
    }
  };

  return (
    <div className="virtuoso mt-10">
      <Portal portalId="rangepicker-live">
        <RangePicker
          disabledDate={disabledDate}
          className="mr-10"
          onChange={handleRangeChange}
          format={POST_DATE_FORMAT}
        />
      </Portal>
      <Portal portalId="author-select">
        <Select
          placeholder="Select Author"
          allowClear
          className="mr-10"
          loading={authorsLoading}
          onPopupScroll={handleAuthorScroll}
          onChange={(e) => {
            setLivePosts([]);
            if (e) {
              setIsFilterApplied(true);
              setAuthorSelected(e);
            } else {
              setIsFilterApplied(false);
              setAuthorSelected(null);
            }
          }}
        >
          {map(authorsData, (author) => {
            return <Option key={author?.id}>{author?.name}</Option>;
          })}
        </Select>
      </Portal>
      <Portal portalId="filter-select">
        <Select
          placeholder="Filter By"
          onChange={filterByChange}
          allowClear
          className="mr-10"
          value={filterBy}
        >
          {map(FilterByOptions, (option) => (
            <Option key={option?.name} value={option?.value}>
              {option?.name}
            </Option>
          ))}
        </Select>
      </Portal>
      <Portal portalId="sort-select">
        <Select
          placeholder="Sort By"
          value={filterBy ? sortBy : undefined}
          onChange={sortByChange}
          allowClear
          className="mr-10"
        >
          {map(SortByOptions, (option) => (
            <Option key={option?.name} value={option?.value}>
              {option?.name}
            </Option>
          ))}
        </Select>
      </Portal>
      {loading && livePosts?.length === 0 ? (
        <Row className="d-flex justify-center">
          <Col>
            <LoaderComponent
              indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
            />
          </Col>
        </Row>
      ) : (
        <>
          {livePosts?.length === 0 ? (
            <Empty />
          ) : (
            <Virtuoso
              ref={virtuoso}
              data={livePosts}
              endReached={loadMorePosts}
              itemContent={(index, post) => {
                if (!post) {
                  return null;
                }
                return (
                  <LivePost
                    index={index}
                    key={post?.id}
                    post={post}
                    selectedPost={selectedPost}
                    setSelectedPost={setSelectedPost}
                    setSelectedPostData={setSelectedPostData}
                  />
                );
              }}
              footer={() => {
                if (loading)
                  return (
                    <Row className="d-flex justify-center">
                      <Col>
                        <LoaderComponent
                          indicator={
                            <LoadingOutlined style={{ fontSize: 44 }} spin />
                          }
                          setHeight={10}
                        />
                      </Col>
                    </Row>
                  );
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default LivePostList;
