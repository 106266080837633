import {
  CheckOutlined,
  EyeInvisibleOutlined,
  HighlightOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Col, DatePicker, Empty, Row, Select, Switch } from 'antd';
import { cloneDeep, map, split } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { socialClient } from '../../../../apollo';
import {
  LIMIT,
  POST_DATE_FORMAT,
  PUSHER_EVENTS,
  SORT_COMMENTS_BY_OPTIONS
} from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import { usePusher } from '../../../../pusher';
import { FETCH_COMMENTS } from '../graphql/Queries';
import CommentCard from './CommentCard';

const { RangePicker } = DatePicker;
const { Option } = Select;

function disabledDate(current) {
  return current && current > moment()?.endOf('day');
}
const CommentList = ({
  isFilterApplied,
  setIsFilterApplied,
  selectedComment,
  setSelectedComment
}) => {
  const pusher = usePusher();
  const { id: commentId } = useParams();
  const [comments, setComments] = useState([]);
  const virtuoso = useRef(null);
  const [isCommentsEnd, setIsCommentsEnd] = useState(false);
  const [createPusherData, setCreatePusherData] = useState({});
  const [updatePushData, setUpdatePushData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [skipRecord, setSkipRecord] = useState(0);
  const [acceptFilter, setAcceptFilter] = useState(undefined);
  const [highlightFilter, setHighlightFilter] = useState(undefined);
  const [hiddenFilter, setHiddenFilter] = useState(undefined);
  const [dateRange, setDateRange] = useState(null);
  const [sortingFilter, setSortingFilter] = useState({
    sortOn: 'createdAt',
    sortBy: 'DESC'
  });

  const [fetchComments, { loading: commentsLoading, refetch }] = useLazyQuery(
    FETCH_COMMENTS,
    {
      client: socialClient,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
          ...(searchValue && { searchTerm: searchValue }),
          ...(acceptFilter && { isAccepted: acceptFilter }),
          ...(highlightFilter && { isHighlighted: highlightFilter }),
          ...(hiddenFilter && { isHidden: hiddenFilter }),
          ...(dateRange && {
            createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
          }),
          ...(commentId && { id: commentId })
        },
        sort: sortingFilter
      },
      onCompleted: (res) => {
        setSelectedComment(res?.listCommentsModeration?.data?.[0]);
        if (res?.listCommentsModeration?.data?.length < LIMIT) {
          setIsCommentsEnd(true);
        } else {
          setIsCommentsEnd(false);
        }
        setComments([...comments, ...res?.listCommentsModeration?.data]);
      },
      onError: () => {}
    }
  );

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreComments = async () => {
    if (!isCommentsEnd) {
      setSkipRecord((prevState) => prevState + 1);
      await fetchComments({
        variables: {
          filter: {
            skip: (skipRecord + 1) * LIMIT,
            limit: LIMIT,
            ...(searchValue && { searchTerm: searchValue }),
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    }
  };

  const updateComments = useCallback(
    (data, currentPusherState) => {
      if (currentPusherState === 'create') {
        const tempComment = [...comments];
        if (!isFilterApplied) {
          setComments([{ ...data }, ...tempComment]);
          setSelectedComment(data);
        }
      } else {
        const id = comments?.findIndex((comment) => comment?.id === data?.id);
        if (id !== -1) {
          const newComments = cloneDeep(comments);
          newComments[id] = { ...newComments[id], ...data };
          setComments([...newComments]);
          setSelectedComment(data);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, createPusherData, updatePushData]
  );

  const commentCreateHandler = (newData) => {
    if (newData?.comment?.node) {
      const nodeData = newData?.comment?.node;
      setCreatePusherData(nodeData);
    }
  };

  const newCommentCreateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData, 'create');
    }
  };

  const commentUpdateHandler = (newData) => {
    if (newData?.comment?.node) {
      const nodeData = newData?.comment?.node;
      setUpdatePushData(nodeData);
    }
  };

  const newCommentUpdateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData);
    }
  };

  useEffect(() => {
    if (pusher) {
      const commentChannel = pusher.subscribe(
        PUSHER_EVENTS.COMMENT_MODERATION_EVENT
      );
      commentChannel.bind('UPDATED', commentUpdateHandler);
      commentChannel.bind('CREATED', commentCreateHandler);

      return () => {
        commentChannel.unbind('UPDATED', commentUpdateHandler);
        commentChannel.unbind('CREATED', commentCreateHandler);
        pusher.unsubscribe(PUSHER_EVENTS.COMMENT_MODERATION_EVENT);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createPusherData?.id) {
      newCommentCreateHandler({ ...createPusherData });
      setCreatePusherData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPusherData, updateComments]);

  useEffect(() => {
    if (updatePushData?.id) {
      newCommentUpdateHandler({ ...updatePushData });
      setUpdatePushData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePushData, updateComments]);

  const handleSearch = (value) => {
    if (value) {
      setIsFilterApplied(true);
      setSearchValue(value);
      setComments([]);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            searchTerm: value,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    } else {
      setIsFilterApplied(false);
      setSearchValue('');
      setComments([]);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    }
  };

  const handleAcceptChange = (checked) => {
    if (checked) {
      setComments([]);
      setIsFilterApplied(true);
      setAcceptFilter(checked);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            isAccepted: checked,
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    } else {
      setComments([]);
      setIsFilterApplied(false);
      setAcceptFilter(undefined);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    }
  };

  const handleHighlightChange = (checked) => {
    if (checked) {
      setComments([]);
      setIsFilterApplied(true);
      setHighlightFilter(checked);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            isHighlighted: true,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    } else {
      setComments([]);
      setIsFilterApplied(false);
      setHighlightFilter(undefined);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    }
  };

  const handleHiddenChange = (checked) => {
    if (checked) {
      setComments([]);
      setIsFilterApplied(true);
      setHiddenFilter(checked);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            isHidden: true,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    } else {
      setComments([]);
      setIsFilterApplied(false);
      setHiddenFilter(undefined);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: sortingFilter
        }
      });
    }
  };

  const handleRangeChange = (date) => {
    if (date && date.length > 1) {
      const day1 = date[0].startOf('day');
      const day2 = date[1].isSame(new Date(), 'day')
        ? date[1]
        : date[1].endOf('day');
      setDateRange([day1, day2]);
      setComments([]);
      setIsFilterApplied(true);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            createdAt: { before: day2, after: day1 },
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(hiddenFilter && { isHidden: hiddenFilter })
          },
          sort: sortingFilter
        }
      });
    } else {
      setComments([]);
      setIsFilterApplied(false);
      setDateRange(null);
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(hiddenFilter && { isHidden: hiddenFilter })
          },
          sort: sortingFilter
        }
      });
    }
  };

  const handleSortChange = (data) => {
    const sortOn = split(data, '_')?.[0];
    const sortBy = split(data, '_')?.[1];
    if (data) {
      setComments([]);
      setIsFilterApplied(true);
      setSortingFilter({
        sortBy,
        sortOn
      });
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: {
            sortOn,
            sortBy
          }
        }
      });
    } else {
      setComments([]);
      setIsFilterApplied(false);
      setSortingFilter({
        sortBy: 'DESC',
        sortOn: 'createdAt'
      });
      fetchComments({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            ...(acceptFilter && { isAccepted: acceptFilter }),
            ...(highlightFilter && { isHighlighted: highlightFilter }),
            ...(searchValue && { searchTerm: searchValue }),
            ...(hiddenFilter && { isHidden: hiddenFilter }),
            ...(dateRange && {
              createdAt: { before: dateRange?.[1], after: dateRange?.[0] }
            })
          },
          sort: {
            sortOn: 'createdAt',
            sortBy: 'DESC'
          }
        }
      });
    }
  };

  return (
    <div className="virtuoso mt-10">
      <Portal portalId="filter-switch">
        <div className="d-flex">
          <p className="mb-0 d-flex align-center mr-10">
            <Switch className="mr-5" onChange={handleAcceptChange} />{' '}
            <CheckOutlined className="mr-5" />
            Accept
          </p>
          <p className="mb-0 d-flex align-center mr-10">
            <Switch className="mr-5" onChange={handleHighlightChange} />{' '}
            <HighlightOutlined className="mr-5" />
            Highlight
          </p>
          <p className="mb-0 d-flex align-center mr-10">
            <Switch className="mr-5" onChange={handleHiddenChange} />{' '}
            <EyeInvisibleOutlined className="mr-5" />
            Hidden
          </p>
        </div>
      </Portal>
      <Portal portalId="range-picker">
        <RangePicker
          disabledDate={disabledDate}
          className="mr-10"
          format={POST_DATE_FORMAT}
          onChange={handleRangeChange}
        />
      </Portal>
      <Portal portalId="sorting-select">
        <Select placeholder="Sort By" allowClear onChange={handleSortChange}>
          {map(SORT_COMMENTS_BY_OPTIONS, (option) => (
            <Option key={option?.name} value={option?.value}>
              {option?.name}
            </Option>
          ))}
        </Select>
      </Portal>
      <Portal portalId="search-component">
        <SearchComponent className="search-component" getData={handleSearch} />
      </Portal>
      {commentsLoading && comments?.length === 0 ? (
        <LoaderComponent
          indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
        />
      ) : (
        <>
          {comments?.length === 0 ? (
            <Empty />
          ) : (
            <Virtuoso
              ref={virtuoso}
              data={comments}
              endReached={loadMoreComments}
              itemContent={(index, comment) => {
                if (!comment) {
                  return null;
                }
                return (
                  <CommentCard
                    index={index}
                    key={comment?.id}
                    comment={comment}
                    refetch={refetch}
                    comments={comments}
                    setComments={setComments}
                    selectedComment={selectedComment}
                    setSelectedComment={setSelectedComment}
                  />
                );
              }}
              footer={() => {
                if (commentsLoading)
                  return (
                    <Row className="d-flex justify-center">
                      <Col>
                        <LoaderComponent
                          indicator={
                            <LoadingOutlined style={{ fontSize: 44 }} spin />
                          }
                        />
                      </Col>
                    </Row>
                  );
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CommentList;
