import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import confirm from 'antd/lib/modal/confirm';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { socialClient } from '../../../../apollo';
import { ACTIONS, PUSHER_EVENTS } from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { usePusher } from '../../../../pusher';
import { UPDATE_COMMENT } from '../graphql/Mutation';
import { FETCH_COMMENTS } from '../graphql/Queries';
import SideCommentCard from './SideCommentCard';

const ParentComment = (props) => {
  const { selectedComment, commentCount, setCommentCount } = props;
  const pusher = usePusher();
  const id = get(selectedComment, 'id');
  const [showActions, setShowActions] = useState(false);
  const [comment, setComment] = useState(null);
  const [updateData, setUpdateData] = useState({});

  const [fetchComment, { loading, refetch }] = useLazyQuery(FETCH_COMMENTS, {
    client: socialClient,
    fetchPolicy: 'network-only',
    variables: {
      filter: { id }
    },
    onCompleted(res) {
      setComment(res?.listCommentsModeration?.data?.[0]);
      setCommentCount(res?.listCommentsModeration?.data?.[0]?.replyCount);
    }
  });

  const [updateComment] = useMutation(UPDATE_COMMENT, {
    client: socialClient,
    onError() {}
  });

  useEffect(() => {
    fetchComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateComments = useCallback(
    (data) => {
      setComment({ ...data });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comment, updateData]
  );

  const commentUpdateHandler = (newData) => {
    if (newData?.comment?.node) {
      const nodeData = newData?.comment?.node;
      setUpdateData(nodeData);
    }
  };

  const newCommentUpdateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData);
    }
  };

  useEffect(() => {
    if (pusher) {
      const commentChannel = pusher.subscribe(
        `${PUSHER_EVENTS.COMMENT_MODERATION_EVENT}_${id}`
      );
      commentChannel.bind('UPDATED', commentUpdateHandler);

      return () => {
        commentChannel.unbind('UPDATED', commentUpdateHandler);
        pusher.unsubscribe(`${PUSHER_EVENTS.COMMENT_MODERATION_EVENT}_${id}`);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (updateData?.id) {
      newCommentUpdateHandler({ ...updateData });
      setUpdateData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, updateComments]);

  const handleShowAction = () => {
    setShowActions(!showActions);
  };

  const handleActionButtons = (e, data, fieldName) => {
    e.stopPropagation();
    let value;
    let title;
    let okText;
    let okType;
    let variables = { where: { id: data?.id } };
    if (fieldName === ACTIONS.ISHIGHLIGHTED) {
      value = get(data, ACTIONS.ISHIGHLIGHTED);
      title = `Are you sure, you want to ${
        value ? 'unhighlight' : 'highlight'
      } this comment?`;
      okText = value ? 'Unhighlight' : 'Highlight';
      okType = value ? 'danger' : 'primary';
      variables = { ...variables, data: { isHighlighted: !value } };
    } else if (fieldName === ACTIONS.ISHIDDEN) {
      value = get(data, ACTIONS.ISHIDDEN);
      title = `Are you sure, you want to ${
        value ? 'unhide' : 'hide'
      } this comment?`;
      okText = value ? 'Unhide' : 'Hide';
      okType = value ? 'primary' : 'danger';
      variables = { ...variables, data: { isHidden: !value } };
    } else {
      value = get(data, ACTIONS.ISACCEPTED);
      title = `Are you sure, you want to ${
        value ? 'unaccept' : 'accept'
      } this comment ? `;
      okText = value ? 'Unaccept' : 'Accept';
      okType = value ? 'danger' : 'primary';
      variables = { ...variables, data: { isAccepted: !value } };
    }
    confirm({
      title,
      okText,
      okType,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      className: 'action-buttons-modal',
      onOk: async () => {
        return new Promise((resolve) => {
          updateComment({
            variables
          }).then(() => {
            refetch().then(() => {
              resolve();
            });
          });
        });
      }
    });
  };

  if (loading)
    return (
      <LoaderComponent
        indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
      />
    );

  return (
    <div>
      <SideCommentCard
        isParent
        showActions={showActions}
        handleShowAction={handleShowAction}
        comment={comment}
        commentCount={commentCount}
        handleActionButtons={handleActionButtons}
      />
    </div>
  );
};

export default ParentComment;
