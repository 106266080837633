import React, { useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import { VIDEO_URL } from '../common/constants';

const videoPlayerFunction = (width, height, data) => {
  return (
    <div className="d-flex align-center justify-center p-14">
      <ReactPlayer
        width={width}
        height={height}
        playing
        controls
        url={data?.video?.videoUrl || VIDEO_URL}
      />
    </div>
  );
};

const VideoPlayer = (props) => {
  const { height, width, data } = props;
  const reactVideoPlayer = useMemo(
    () => videoPlayerFunction(width, height, data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.id]
  );
  return reactVideoPlayer;
};

export default VideoPlayer;
