import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Empty, Modal, Row, Spin } from 'antd';
import { cloneDeep, get, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { socialClient } from '../../../../apollo';
import { PUSHER_EVENTS } from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { usePusher } from '../../../../pusher';
import { UPDATE_COMMENT_MODERATION } from '../../post/graphql/Mutations';
import { COMMENT_MODERATION } from '../../post/graphql/Queries';
import { HIDE_ALL_COMMENTS_BY_USER } from '../graphql/Mutation';
import LiveComment from './LiveComment';

const { confirm } = Modal;
const LiveCommentList = (props) => {
  const { selectedPost, selectedPostRef, commentSearch } = props;
  const pusher = usePusher();
  const [comments, _setComments] = useState([]);
  const virtuoso = useRef(null);
  const commentsRef = useRef(comments);
  const [initialVariable] = useState({
    skip: 0,
    limit: 10
  });

  const [updateCommentModeration] = useMutation(UPDATE_COMMENT_MODERATION, {
    client: socialClient
  });

  const [hideAllCommentsByPerson] = useMutation(HIDE_ALL_COMMENTS_BY_USER, {
    client: socialClient
  });

  const setComments = (data) => {
    commentsRef.current = data;
    _setComments(data);
  };

  const commentUpdateHandler = (data) => {
    if (data?.comment?.node) {
      const id = commentsRef?.current?.findIndex(
        (comment) => comment?.id === data?.comment?.node?.id
      );
      if (id !== -1) {
        const newComments = cloneDeep(commentsRef?.current);
        let activityLogs = [
          ...newComments[id]?.activityLogs,
          ...data?.comment?.node?.activityLogs
        ];
        activityLogs = uniqBy(activityLogs, 'id');
        newComments[id] = {
          ...newComments[id],
          ...data?.comment?.node,
          activityLogs: activityLogs
        };
        setComments([...newComments]);
      }
    }
  };

  const commentCreateHandler = (data) => {
    if (data?.comment?.node?.postId) {
      if (selectedPostRef?.current === data?.comment?.node?.postId) {
        setComments([data?.comment?.node, ...commentsRef?.current]);
      }
    }
  };

  const userCommentHidden = (data) => {
    if (data?.postId === selectedPostRef?.current) {
      const newComments = commentsRef?.current?.map((comment) => {
        if (get(comment, 'createdBy.id') === data?.personId) {
          // eslint-disable-next-line no-param-reassign
          comment.isHidden = true;
        }
        return comment;
      });
      setComments(newComments);
    }
  };

  useEffect(() => {
    const commentChannel = pusher.subscribe(
      `${PUSHER_EVENTS.COMMENT_MODERATION_EVENT}_${selectedPost}`
    );
    commentChannel.bind('UPDATED', commentUpdateHandler);
    commentChannel.bind('CREATED', commentCreateHandler);
    commentChannel.bind('USER_COMMENT_HIDDEN', userCommentHidden);

    return () => {
      commentChannel.unbind('UPDATED', commentUpdateHandler);
      commentChannel.unbind('CREATED', commentCreateHandler);
      commentChannel.unbind('USER_COMMENT_HIDDEN', userCommentHidden);
      pusher.unsubscribe(
        `${PUSHER_EVENTS.COMMENT_MODERATION_EVENT}_${selectedPost}`
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPost]);

  const {
    data,
    loading: isCommentLoading,
    fetchMore: fetchMoreComments
  } = useQuery(COMMENT_MODERATION, {
    variables: {
      filter: {
        skip: initialVariable?.skip,
        limit: initialVariable?.limit,
        searchTerm: commentSearch,
        postId: selectedPost
      }
    },
    client: socialClient,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!isCommentLoading && data?.commentThreadModeration) {
      const commentList = data?.commentThreadModeration?.data;
      setComments(commentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentLoading]);

  const loadMoreComments = async () => {
    try {
      await fetchMoreComments({
        variables: {
          filter: {
            skip: comments?.length,
            limit: initialVariable?.limit,
            searchTerm: commentSearch,
            postId: selectedPost
          }
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { commentThreadModeration: newCommentsData } =
              fetchMoreResult;
            setComments(uniqBy([...comments, ...newCommentsData?.data]), 'id');
          }
        }
      });
    } catch (error) {
      return error;
    }
  };

  const showCommentHiddenConfirm = (commentId, isHidden) => {
    confirm({
      title: `Are you sure, you want to ${
        isHidden ? 'unhide' : 'hide'
      } this comment?`,
      okText: isHidden ? 'Unhide' : 'Hide',
      okType: isHidden ? 'primary' : 'danger',
      className: 'danger-btn',
      async onOk() {
        try {
          const variables = {
            data: { isHidden: !isHidden },
            where: { id: commentId }
          };
          const response = await updateCommentModeration({
            variables: variables
          });
          if (response && get(response, 'data.updateCommentModeration')) {
            const newComments = cloneDeep(comments);
            const id = newComments?.findIndex(
              (post) =>
                post?.id ===
                get(response, 'data.updateCommentModeration.data.id')
            );
            if (id !== -1) {
              newComments[id].isHidden = !isHidden;
            }
            setComments(newComments);
          }
        } catch (error) {
          return error;
        }
      }
    });
  };

  const handleHideAllComment = (user) => {
    confirm({
      title: `Are you sure, you want to hide all comments by this user?`,
      okText: 'Hide',
      okType: 'danger',
      className: 'danger-btn',
      async onOk() {
        try {
          const response = await hideAllCommentsByPerson({
            variables: { where: { personId: user?.id, postId: selectedPost } }
          });
          if (response && get(response, 'data.hideAllCommentsByPerson')) {
            const hideComments = cloneDeep(comments);
            const newComments = hideComments?.map((comment) => {
              if (get(comment, 'createdBy.id') === user?.id) {
                // eslint-disable-next-line no-param-reassign
                comment.isHidden = true;
              }
              return comment;
            });
            setComments(newComments);
          }
        } catch (error) {
          return error;
        }
      }
    });
  };

  if (isCommentLoading) {
    return <LoaderComponent />;
  }
  if (comments?.length === 0) {
    return <Empty />;
  }
  return (
    <div className="side-virtuoso mt-10">
      {isCommentLoading && comments?.length === 0 ? (
        <LoaderComponent />
      ) : (
        <Virtuoso
          overscan={100}
          ref={virtuoso}
          data={comments}
          endReached={
            comments?.length < data?.commentThreadModeration?.count &&
            loadMoreComments
          }
          itemContent={(index, comment) => {
            if (!comment) {
              return null;
            }
            return (
              <LiveComment
                index={index}
                key={comment?.id}
                data={comment}
                showCommentHiddenConfirm={showCommentHiddenConfirm}
                handleHideAllComment={handleHideAllComment}
              />
            );
          }}
          footer={() => {
            if (isCommentLoading) {
              return (
                <Row className="d-flex justify-center">
                  <Col>
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 44 }} spin />
                      }
                    />
                  </Col>
                </Row>
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default LiveCommentList;
