import { LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { cloneDeep, get, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { socialClient } from '../../../../apollo';
import { LIMIT, PUSHER_EVENTS } from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { usePusher } from '../../../../pusher';
import { COMMENT_MODERATION } from '../../post/graphql/Queries';
import SideCommentCard from './SideCommentCard';

const SideCommentList = (props) => {
  const { selectedComment, setCommentCount } = props;
  const pusher = usePusher();
  const parentId = get(selectedComment, 'id');
  const postId = get(selectedComment, 'postId');
  const [comments, setComments] = useState([]);
  const virtuoso = useRef(null);
  const [isCommentsEnd, setIsCommentsEnd] = useState(false);
  const [skipRecord, setSkipRecord] = useState(0);
  const [createPusherData, setCreatePusherData] = useState({});
  const [updatePushData, setUpdatePushData] = useState({});

  const [fetchComments, { loading }] = useLazyQuery(COMMENT_MODERATION, {
    client: socialClient,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        postId,
        ...(parentId && { parentId }),
        skip: 0,
        limit: LIMIT
      }
    },
    onCompleted: (res) => {
      if (res?.commentThreadModeration?.data?.length < LIMIT) {
        setIsCommentsEnd(true);
      } else {
        setIsCommentsEnd(false);
      }
      setComments(
        uniqBy([...comments, ...res?.commentThreadModeration?.data], 'id')
      );
    },
    onError() {}
  });

  useEffect(() => {
    setComments([]);
    setSkipRecord(0);
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const loadMoreComments = async () => {
    if (!isCommentsEnd) {
      setSkipRecord((prevState) => prevState + 1);
      await fetchComments({
        variables: {
          filter: {
            postId,
            ...(parentId && { parentId }),
            skip: (skipRecord + 1) * LIMIT,
            limit: LIMIT
          }
        }
      });
    }
  };

  const updateComments = useCallback(
    (data, currentPusherState) => {
      if (currentPusherState === 'create') {
        if (postId === data?.postId) {
          setCommentCount((oldCount) => oldCount + 1);
          if (
            (!parentId && !get(data, 'parentId')) ||
            parentId === get(data, 'parentId')
          ) {
            const tempComment = [...comments];
            setComments([{ ...data }, ...tempComment]);
          }
        }
      } else {
        const id = comments?.findIndex((comment) => comment?.id === data?.id);
        if (id !== -1) {
          const newComments = cloneDeep(comments);
          newComments[id] = { ...newComments[id], ...data };
          setComments([...newComments]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, createPusherData, updatePushData]
  );

  const commentCreateHandler = (newData) => {
    if (newData?.comment?.node) {
      const nodeData = newData?.comment?.node;
      setCreatePusherData(nodeData);
    }
  };

  const newCommentCreateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData, 'create');
    }
  };

  const commentUpdateHandler = (newData) => {
    if (newData?.comment?.node) {
      const nodeData = newData?.comment?.node;
      setUpdatePushData(nodeData);
    }
  };

  const newCommentUpdateHandler = (newData) => {
    if (newData) {
      const nodeData = { ...newData };
      updateComments(nodeData);
    }
  };

  useEffect(() => {
    if (pusher) {
      const commentChannel = pusher.subscribe(
        PUSHER_EVENTS.COMMENT_MODERATION_EVENT
      );
      commentChannel.bind('UPDATED', commentUpdateHandler);
      commentChannel.bind('CREATED', commentCreateHandler);

      return () => {
        commentChannel.unbind('UPDATED', commentUpdateHandler);
        commentChannel.unbind('CREATED', commentCreateHandler);
        pusher.unsubscribe(PUSHER_EVENTS.COMMENT_MODERATION_EVENT);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createPusherData?.id) {
      newCommentCreateHandler({ ...createPusherData });
      setCreatePusherData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPusherData, updateComments]);

  useEffect(() => {
    if (updatePushData?.id) {
      newCommentUpdateHandler({ ...updatePushData });
      setUpdatePushData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePushData, updateComments]);

  return (
    <>
      {loading && comments?.length === 0 ? (
        <LoaderComponent />
      ) : (
        <>
          {comments?.length > 0 && (
            <div className="side-virtuoso mt-10">
              <Virtuoso
                ref={virtuoso}
                data={comments}
                endReached={loadMoreComments}
                itemContent={(index, comment) => {
                  if (!comment) {
                    return null;
                  }
                  return (
                    <SideCommentCard
                      index={index}
                      key={comment?.id}
                      comment={comment}
                    />
                  );
                }}
                footer={() => {
                  if (loading)
                    return (
                      <Row className="d-flex justify-center">
                        <Col>
                          <LoaderComponent
                            indicator={
                              <LoadingOutlined style={{ fontSize: 44 }} spin />
                            }
                          />
                        </Col>
                      </Row>
                    );
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SideCommentList;
