import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CommentWrapper from './comment';
import LiveWrapper from './live';
import ModerationHeader from './ModerationHeader';
import PostWrapper from './post';

const ModerationWrapper = () => {
  return (
    <>
      <ModerationHeader />
      <Switch>
        <Route
          exact
          path={ROUTES.MODERATION}
          render={() => <Redirect to={`${ROUTES.MODERATION}/comments`} />}
        />
        <Route
          path={`${ROUTES.MODERATION}/comments`}
          component={CommentWrapper}
        />
        <Route path={`${ROUTES.MODERATION}/live`} component={LiveWrapper} />
        <Route path={`${ROUTES.MODERATION}/posts`} component={PostWrapper} />
      </Switch>
    </>
  );
};

export default ModerationWrapper;
