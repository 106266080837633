import { Col, Divider, Row } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ADMIN_ROLES, VIDEO_STATUS } from '../../../common/constants';
import AddCommentAsAuthor from '../../../components/AddCommentAsAuthor';
import CommonCard from '../../../components/CommonCard';
import LiveCommentList from './components/LiveCommentList';
import LivePostList from './components/LivePostList';
import LiveVideo from './components/LiveVideo';

const Live = () => {
  const { state } = useContext(AppContext);
  const [selectedPostData, setSelectedPostData] = useState({});
  const [commentSearch, setCommentSearch] = useState(null);
  const [selectedPost, _setSelectedPost] = useState(null);
  const [livePost, setLivePost] = useState({});
  const selectedPostRef = useRef(selectedPost);
  const handleSearchChange = (text) => {
    setCommentSearch(text);
  };

  const setSelectedPost = (data) => {
    selectedPostRef.current = data;
    _setSelectedPost(data);
  };

  return (
    <div>
      <div className="moderation-filters justify-end ">
        <div className="d-flex">
          <div className="d-flex mr-10">
            <div id="rangepicker-live" />
            <div id="author-select" />
            <div id="filter-select" />
            <div id="sort-select" />
          </div>
        </div>
      </div>
      <Divider className="m-0 " />
      <Row gutter={12} className="moderation-content">
        <Col span={8} className="live-post-card pointer">
          <LivePostList
            setSelectedPost={setSelectedPost}
            selectedPost={selectedPost}
            setSelectedPostData={setSelectedPostData}
          />
        </Col>
        <Col span={16}>
          {selectedPost && (
            <CommonCard className="video-card">
              <LiveVideo
                selectedPostData={selectedPostData}
                selectedPost={selectedPost}
                setLivePost={setLivePost}
                handleSearchChange={handleSearchChange}
              />
              <LiveCommentList
                selectedPost={selectedPost}
                commentSearch={commentSearch}
                selectedPostRef={selectedPostRef}
              />
              {ADMIN_ROLES?.includes(state?.currentUser?.role) &&
                livePost?.video?.videoState === VIDEO_STATUS?.RECORDING &&
                livePost?.video.duration && (
                  <div className="mt-10">
                    <AddCommentAsAuthor
                      postId={selectedPostData?.id}
                      isComment
                    />
                  </div>
                )}
            </CommonCard>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Live;
