import { Card } from 'antd';
import React from 'react';
import DashboardTable from './components/DashboardTable';

const Dashboard = () => {
  return (
    <Card className="m-12">
      <div className="d-flex align-center justify-between">
        <h1>Dashboard</h1>
      </div>
      <hr />
      <DashboardTable />
    </Card>
  );
};

export default Dashboard;
