import { CloseOutlined } from '@ant-design/icons';
import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  split
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/browser';
import { message } from 'antd';
import { get, isObject } from 'lodash';
import React from 'react';
import Cookies from 'universal-cookie';
import { version } from '../package.json';
import { EXPORT_MESSAGE, TOKEN } from './common/constants';
import history from './historyData';

export const cacheData = new InMemoryCache();
let disableToastTimeout = null;
const cookies = new Cookies();

export const toast = ({ message: content, type, duration }) => {
  message.destroy();
  switch (type) {
    case 'info':
      message.info({ content, duration });
      break;
    case 'success':
      if (content === EXPORT_MESSAGE) {
        message.success({
          content: (
            <span>
              {content}
              <CloseOutlined
                className="toast-close"
                onClick={() => message?.destroy()}
              />
            </span>
          ),
          duration: 30
        });
      } else {
        message.success({ content, duration });
      }
      break;
    case 'warning':
      message.warning({ content, duration });
      break;
    case 'error':
      message.error({ content, duration });
      break;
    default:
      break;
  }
};

const responseMessageLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data } = response;
    if (
      data &&
      isObject(data) &&
      Object.keys(data).length > 0 &&
      data[`${Object.keys(data)[0]}`] &&
      data[`${Object.keys(data)[0]}`].message
    ) {
      if (Object.keys(data)[0] === 'forgotUserPassword') {
        if (data[`${Object.keys(data)[0]}`].status !== 'ERROR') {
          setTimeout(() => {
            toast({
              message:
                data[`${Object.keys(data)[0]}`].message ||
                'Operation successful',
              type: 'success'
            });
          }, 1000);
        }
      } else {
        setTimeout(() => {
          const oResponse = data[`${Object.keys(data)[0]}`];

          if (!response) {
            return;
          }

          toast({
            message: oResponse.message || 'Operation successful',
            type: oResponse.status === 'ERROR' ? 'error' : 'success'
          });
        }, 1000);
      }
    }
    return response;
  });
});

const errorLink = onError((options) => {
  const { graphQLErrors, networkError, response } = options;

  if (networkError && networkError.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError.result && networkError.result.message) {
        toast({
          message: networkError.result.message,
          type: 'error'
        });
      }
    }, 200);

    history.replace('/logout');
    return;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    const isForBidden =
      get(graphQLErrors[0], 'extensions.code') === 'FORBIDDEN';
    if (!isForBidden) {
      setTimeout(() => {
        toast({
          message: graphQLErrors[0].message,
          type: 'error'
        });
      }, 1000);
    }
  } else {
    setTimeout(() => {
      toast({
        message: 'Something went wrong!',
        type: 'error'
      });
    }, 1000);
  }

  if (response) {
    response.errors.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;

      // Enable when sentry integrated
      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
        )
      );

      if (extensions && extensions.code === 'FORBIDDEN') {
        history.replace('/access-denied');
      }

      if (
        extensions &&
        (extensions.code === 'UNAUTHENTICATED' ||
          extensions.code === 405 ||
          extensions.code === 'SESSION_EXPIRED' ||
          extensions.exception.name === 'JsonWebTokenError')
      ) {
        if (errorMessage === 'Your session has expired. Please log in again!') {
          history.replace('/unauthenticated');
        } else {
          history.replace('/logout');
        }
      }

      // eslint-disable-next-line no-console
      return console.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(new Error(`[Network error]: ${networkError}`));
  }
});

const authLink = setContext((ctx, { headers }) => {
  // eslint-disable-next-line no-undef
  const authorizationToken = localStorage.getItem(TOKEN);
  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    Authorization: authorizationToken ? `Bearer ${authorizationToken}` : ''
  };

  return {
    headers: newHeaders
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_GRAPH_URL
});

const httpChatLink = createHttpLink({
  uri: process.env.REACT_APP_CHAT_SERVER_GRAPH_URL
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SERVER_SOCKET_URL,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      // eslint-disable-next-line no-undef
      const authorizationToken = localStorage.getItem(TOKEN);
      return {
        authorization: authorizationToken
          ? `Bearer ${authorizationToken}`
          : null
      };
    }
  }
});

const wsChatLink = new WebSocketLink({
  uri: process.env.REACT_APP_CHAT_SERVER_SOCKET_URL,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      // eslint-disable-next-line no-undef
      const authorizationToken = localStorage.getItem(TOKEN);
      return {
        authorization: authorizationToken
          ? `Bearer ${authorizationToken}`
          : null
      };
    }
  }
});

// eslint-disable-next-line no-undef
window.addEventListener('beforeunload', () => {
  // @ts-ignore - the function is private in typescript
  wsLink.subscriptionClient.close();
  wsChatLink.subscriptionClient.close();
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const chatLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsChatLink,
  httpChatLink
);

const socialAuthLink = setContext((ctx, { headers }) => {
  // eslint-disable-next-line no-undef
  const authorizationToken = localStorage.getItem(TOKEN);
  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    Authorization: authorizationToken ? `Bearer ${authorizationToken}` : '',
    'Access-Control-Allow-Origin': '*'
  };

  return {
    headers: newHeaders
  };
});

const httpSocialLink = createHttpLink({
  uri: process.env.REACT_APP_SOCIAL_FEED_GRAPH_URL
});

const httpAuthServerLink = createHttpLink({
  uri: process.env.REACT_APP_AUTH_GRAPH_URL
});

const appClient = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, link])
});

const chatClient = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, chatLink]),
  name: 'CMS',
  version
});

const socialClient = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, socialAuthLink, httpSocialLink])
});

const authClient = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpAuthServerLink])
});

export default appClient;

export { cookies, chatClient, socialClient, authClient };
