import { LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Empty, Spin, Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { socialClient } from '../../../../apollo';
import placeholderImage from '../../../../assets/images/BTR.png';
import { VIDEO_STATUS } from '../../../../common/constants';
import CommonCard from '../../../../components/CommonCard';
import LoaderComponent from '../../../../components/LoaderComponent';
import VideoPlayerModal from '../../../../components/VideoPlayerModal';
import { LIST_POST_MODERATION } from '../../post/graphql/Queries';

const Post = (props) => {
  const {
    selectedComment: { postId }
  } = props;

  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [postData, setPostData] = useState(null);

  const [fetchPost, { loading }] = useLazyQuery(LIST_POST_MODERATION, {
    client: socialClient,
    variables: {
      filter: {
        id: postId
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setPostData(res?.listPostModeration?.data?.[0]);
    },
    onError() {}
  });

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setShowPlayerModal(false);
  };
  const handleModal = () => {
    setShowPlayerModal(true);
  };

  const liveVideoRender = () => {
    return (get(postData, 'video.videoState') === VIDEO_STATUS.RECORDING ||
      get(postData, 'video.videoState') === VIDEO_STATUS.DISCONNECTED) &&
      get(postData, 'video.duration') ? (
      <>
        <img
          src={
            get(postData, 'video.videoGif')
              ? get(postData, 'video.videoGif')
              : placeholderImage
          }
          alt={postData?.video?.id}
        />
        <Button
          icon={<PlayCircleOutlined style={{ fontSize: '44px' }} />}
          shape="circle"
          className="play-button"
          size="large"
          onClick={handleModal}
        />
      </>
    ) : (
      <div className="d-flex justify-content-center">
        {get(postData, 'video.status') === VIDEO_STATUS.LIVE &&
        get(postData, 'video.videoState') !== VIDEO_STATUS.DISCONNECTED ? (
          <Spin tip="Please Wait...">
            <img src={placeholderImage} alt="live-post-video" />
          </Spin>
        ) : (
          <img src={placeholderImage} alt="live-post-video" />
        )}
      </div>
    );
  };
  return (
    <div>
      <CommonCard>
        {loading ? (
          <LoaderComponent
            indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
          />
        ) : (
          <>
            {isEmpty(postData) ? (
              <Empty />
            ) : (
              <>
                <div className="d-flex align-center">
                  <span className="mr-5">
                    {postData?.author && (
                      <Avatar
                        src={postData?.author?.profileImage || ''}
                        size="large"
                      >
                        {postData?.author?.name?.charAt(0) || ''}
                      </Avatar>
                    )}
                  </span>
                  <div className="d-flex flex-vertical">
                    {postData?.author && (
                      <h4 className="mb-0 font-700">
                        {postData?.author?.name || ''}
                      </h4>
                    )}
                    {postData?.createdAt && (
                      <span>
                        <Tooltip
                          title={`${moment(postData?.createdAt)
                            .tz('America/Los_Angeles')
                            .format('Do MMMM, hh:mm A')} PST`}
                        >
                          {moment(postData?.createdAt)
                            .tz('America/Los_Angeles')
                            .fromNow()}
                        </Tooltip>
                      </span>
                    )}
                  </div>
                </div>
                <div className="post-title">
                  <p>{postData?.text}</p>
                </div>
                {postData?.video && (
                  <div className="video-container">
                    <VideoPlayerModal
                      showModal={showPlayerModal}
                      handleCancel={handleCancel}
                      data={postData}
                    />

                    {postData?.isLive ? (
                      liveVideoRender()
                    ) : (
                      <>
                        <img
                          src={
                            get(postData, 'video.videoGif')
                              ? get(postData, 'video.videoGif')
                              : placeholderImage
                          }
                          alt={postData?.video?.id}
                          className="image-gif"
                        />
                        {(postData?.video?.videoGif ||
                          postData?.video?.thumbnailUrl) && (
                          <Button
                            icon={
                              <PlayCircleOutlined
                                style={{ fontSize: '44px' }}
                              />
                            }
                            shape="circle"
                            className="play-button"
                            size="large"
                            onClick={handleModal}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
                {postData?.image && (
                  <div className="post-page-media-container image-gif">
                    <img
                      src={postData?.image}
                      alt="post"
                      className="image-gif"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </CommonCard>
    </div>
  );
};

export default Post;
