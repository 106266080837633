import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_AUTHOR_LIST = gql`
  query authors($filter: AuthorsFilter) {
    authors(filter: $filter) {
      count
      data {
        id
        name
        profileImage
        username
      }
    }
  }
`;
