import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ExportUsers from './ExportUsers';

const ExportUsersWrapper = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES?.EXPORT_USERS} component={ExportUsers} />
      </Switch>
    </>
  );
};

export default ExportUsersWrapper;
