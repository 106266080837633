import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditTwoTone,
  StopTwoTone,
  UserOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Popconfirm, Select, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { socialClient } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import { PLAN, ROLES } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import SearchComponent from '../../../components/SearchComponent';
import { BAN_USER, DEACTIVATE_PERSON } from '../graphql/Mutations';
import { GET_REQUEST_PERSON_EXPORT, GET_USERS } from '../graphql/Queries';
import AddEditUserModal from './AddEditUserModal';

const { Option } = Select;

const UsersTable = () => {
  const initialUserFilter = {
    skip: 0,
    limit: 10,
    sort: {
      field: 'createdAt',
      order: 'ASC'
    }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const { state } = useContext(AppContext);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [sortedInfo, setSortedInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [userData, setUserData] = useState('');
  const [deactivatePerson] = useMutation(DEACTIVATE_PERSON);
  const [banUser] = useMutation(BAN_USER, {
    client: socialClient
  });
  const [requestPersonExport] = useLazyQuery(GET_REQUEST_PERSON_EXPORT, {
    fetchPolicy: 'network-only',
    onError() {}
  });
  const [fetchUserData, { loading, data, refetch }] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.personList?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchUserData({ variables: { ...userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setUserFilter({ ...userFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        sort: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchUserData({
        variables: {
          ...userFilter,
          skip,
          limit: pagination.pageSize,
          sort: {
            field: sorter.field,
            order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
          }
        }
      });
    } else {
      setUserFilter({
        ...userFilter,
        sort: {
          field: 'createdAt',
          order: 'ASC'
        }
      });
      fetchUserData({
        variables: {
          ...userFilter,
          skip,
          limit: pagination.pageSize,
          sort: {
            field: 'createdAt',
            order: 'ASC'
          }
        }
      });
    }
  };
  const openUserModal = (record) => {
    setUserData(record);
    setShowModal(true);
  };

  const handleActive = async (userDataCopy) => {
    try {
      const variables = userDataCopy?.isActive
        ? { action: 'DEACTIVATE', personId: userDataCopy?.id }
        : { action: 'ACTIVATE', personId: userDataCopy?.id };
      const response = await deactivatePerson({
        variables: variables
      });
      if (response?.data?.deactivatePerson) {
        refetch();
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  const handleBlockUser = async (blockData) => {
    try {
      const variables = blockData?.isBanned
        ? { data: { action: 'UNBAN' }, where: { id: blockData?.id } }
        : { data: { action: 'BAN' }, where: { id: blockData?.id } };
      const response = await banUser({
        variables: variables
      });
      if (response?.data?.banUser) {
        refetch();
      }
      return response;
    } catch (error) {
      return error;
    }
  };

  const handleExportUsers = async () => {
    setExportLoading(true);
    try {
      const response = await requestPersonExport({
        variables: {
          filter: {
            roles: userFilter?.roleIn,
            subscriptionPlans: userFilter?.subscriptionPlans,
            searchTerm: userFilter?.searchTerm
          }
        }
      });
      if (response?.data?.requestPersonExport) {
        setExportLoading(false);
      }
      return response;
    } catch (error) {
      setExportLoading(false);
      return error;
    }
  };

  const renderActionButtons = (record) => {
    return (
      <div className="action-icons">
        <Tooltip title="Edit">
          <EditTwoTone onClick={() => openUserModal(record)} />
        </Tooltip>
        {state?.currentUser?.id !== record?.id ? (
          <>
            <Popconfirm
              title={`Are you sure you want to ${
                record?.isActive ? 'deactivate' : 'activate'
              } this user?`}
              onConfirm={() => handleActive(record)}
            >
              <Tooltip
                title={`${
                  record?.isActive ? 'Deactivate User' : 'Activate User'
                }`}
              >
                {record?.isActive ? (
                  <CloseCircleTwoTone twoToneColor="#f44336" />
                ) : (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure you want to ${
                record?.isBanned ? 'unblock' : 'block'
              } this user?`}
              onConfirm={() => handleBlockUser(record)}
            >
              <Tooltip
                title={`${record?.isBanned ? 'Unblock User' : 'Block User'}`}
              >
                {record?.isBanned ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <StopTwoTone twoToneColor="#f44336" />
                )}
              </Tooltip>
            </Popconfirm>
          </>
        ) : null}
      </div>
    );
  };

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'profileImage',
      key: 'profileImage',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <div className="profileImage-wrapper">
          {text ? (
            <img src={text} alt={`${record.firstName}`} />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}
        </div>
      )
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      width: '22%',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '22%',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      width: '22%',
      sorter: false
    },
    {
      title: 'Subscription Plan',
      dataIndex: 'subscriptionPlan',
      key: 'subscriptionPlan',
      width: '12%',
      sorter: false
    },
    {
      title: 'Promo Plan',
      dataIndex: 'promoSubscriptionPlan',
      key: 'promoSubscriptionPlan',
      width: '12%',
      sorter: false
    },
    {
      title: 'User Type',
      dataIndex: 'role',
      key: 'role',
      width: '12%',
      sorter: false
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => {
        return renderActionButtons(record);
      }
    }
  ];

  const onSearchChange = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, searchTerm: value });
    fetchUserData({
      variables: { ...userFilter, skip: 0, searchTerm: value }
    });
  };

  const onFilterByRole = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, roleIn: value });
    fetchUserData({
      variables: { ...userFilter, skip: 0, roleIn: value }
    });
  };

  const onFilterByPlan = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, subscriptionPlans: value });
    fetchUserData({
      variables: { ...userFilter, skip: 0, subscriptionPlans: value }
    });
  };

  return (
    <>
      {showModal && (
        <AddEditUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
          refetchUserData={refetch}
        />
      )}
      <div className="d-flex mb-10">
        <SearchComponent
          id="search-container-id"
          className="width-percent-20 mr-10"
          name="Users"
          getData={onSearchChange}
        />
        <Select
          allowClear
          onChange={onFilterByRole}
          placeholder="Filter by role"
          className="width-percent-15 mr-10 "
        >
          {ROLES.map((option) => (
            <Option key={option.name} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        <Select
          allowClear
          onChange={onFilterByPlan}
          placeholder="Filter by subscription plan"
          className="width-percent-15 mr-10"
        >
          {PLAN.map((option) => (
            <Option key={option.name} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        <Button
          id="btn-export-users"
          type="primary"
          onClick={handleExportUsers}
          loading={exportLoading}
        >
          Export Users
        </Button>
      </div>
      <CommonTable
        rowClassName={({ isActive }) => {
          return isActive ? 'is-active' : 'is-inactive';
        }}
        loadingData={loading}
        columns={columns}
        data={data?.personList?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        tableClassName="user-table"
      />
    </>
  );
};

export default UsersTable;
