import { CopyOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Divider, Modal, Spin, Switch, Tag, Tooltip } from 'antd';
import { get, truncate } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { socialClient } from '../../../../apollo';
import btrImage from '../../../../assets/images/BTR.png';
import { PUSHER_EVENTS } from '../../../../common/constants';
import SearchComponent from '../../../../components/SearchComponent';
import VideoPlayer from '../../../../components/VideoPlayer';
import { usePusher } from '../../../../pusher';
import { HIDE_POST } from '../../post/graphql/Mutations';

const { confirm } = Modal;
const LiveVideo = (props) => {
  const { selectedPost, selectedPostData, handleSearchChange, setLivePost } =
    props;
  const [post, _setPost] = useState();
  const postRef = useRef(post);
  const pusher = usePusher();

  const [hidePost] = useMutation(HIDE_POST, {
    client: socialClient
  });
  const setPost = (value) => {
    postRef.current = value;
    _setPost(value);
    setLivePost(value);
  };

  const postUpdateHandler = (data) => {
    if (get(data, 'post.node')) {
      setPost(data?.post?.node);
    }
  };

  useEffect(() => {
    setPost(selectedPostData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPostData]);

  useEffect(() => {
    const postChannel = pusher.subscribe(
      `${PUSHER_EVENTS.POST_EVENT}_${selectedPost}`
    );
    postChannel.bind('UPDATED', postUpdateHandler);
    return () => {
      postChannel.unbind('UPDATED', postUpdateHandler);
      pusher.unsubscribe(`${PUSHER_EVENTS.POST_EVENT}_${selectedPost}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPost]);

  const handleHiddenSwitch = (checked) => {
    confirm({
      title: `Are you sure, you want to ${
        checked ? 'hide' : 'unhide'
      } this live post?`,
      okText: checked ? 'Hide' : 'Unhide',
      okType: checked ? 'danger' : 'primary',
      className: 'danger-btn',
      async onOk() {
        try {
          const variables = checked
            ? { data: { action: 'HIDE' }, postId: selectedPost }
            : { data: { action: 'UNHIDE' }, postId: selectedPost };
          const response = await hidePost({
            variables: variables
          });
          if (response?.data?.hidePost) {
            post.isDeleted = checked;
            setPost(post);
          }
        } catch (error) {
          return error;
        }
      }
    });
  };
  return (
    <div>
      <SearchComponent getData={handleSearchChange} />
      <div className="mt-10 live-video">
        {(get(post, 'video.videoState') === 'RECORDING' ||
          get(post, 'video.videoState') === 'DISCONNECTED') &&
        get(post, 'video.duration') ? (
          <VideoPlayer width="100%" height="320px" data={post} />
        ) : (
          <div className="d-flex justify-center">
            {get(post, 'video.status') === 'LIVE' &&
            get(post, 'video.videoState') !== 'DISCONNECTED' ? (
              <Spin tip="Please Wait...">
                <img src={btrImage} alt="live-post-video" />
              </Spin>
            ) : (
              <img src={btrImage} alt="live-post-video" />
            )}
          </div>
        )}
        {get(post, 'video.videoState') === 'RECORDING' &&
          get(post, 'video.duration') && (
            <Tag color="#FF1F1F" className="live-post-video-tag">
              Live
            </Tag>
          )}
      </div>
      <div className="mt-14 d-flex">
        <Switch
          className="mr-5"
          onChange={handleHiddenSwitch}
          checked={get(post, 'isDeleted')}
        />
        <span className="mr-15">
          <EyeInvisibleOutlined /> Hidden
        </span>
        <div className="d-flex flex-vertical mr-15">
          <span>
            RMTP URL
            <CopyToClipboard text="rtmp://live.mux.com/app">
              <Tooltip
                title="Copied!"
                trigger="click"
                onClick={(e) => e?.stopPropagation()}
              >
                <span id="livepost-rmtp-copylink">
                  <Tooltip title="Click to copy!" placement="bottom">
                    <CopyOutlined className="pointer" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </span>
          <span>rtmp://live.mux.com/app</span>
        </div>
        <div className="d-flex flex-vertical mr-15">
          <span>
            Video URL
            <CopyToClipboard text={get(post, 'video.videoUrl', '')}>
              <Tooltip
                title="Copied!"
                trigger="click"
                onClick={(e) => e?.stopPropagation()}
              >
                <span id="livepost-videourl-copylink">
                  <Tooltip title="Click to copy!" placement="bottom">
                    <CopyOutlined className="pointer" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </span>
          <div>{truncate(get(post, 'video.videoUrl', ''), { length: 30 })}</div>
        </div>
        {/* commented for future use */}
        {/* <div className="d-flex flex-vertical">
          <span>
            Stream Key
            <CopyToClipboard text={get(post, 'video.streamKey', '')}>
              <Tooltip
                title="Copied!"
                trigger="click"
                onClick={(e) => e?.stopPropagation()}
              >
                <span id="livepost-streamkey-copylink">
                  <Tooltip title="Click to copy!" placement="bottom">
                    <CopyOutlined className="pointer" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </span>
          <div>
            {truncate(get(post, 'video.streamKey', ''), { length: 30 })}
          </div>
        </div> */}
      </div>
      <Divider className="divider" />
    </div>
  );
};

export default LiveVideo;
