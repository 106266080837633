import {
  DashboardOutlined,
  FilterOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { get, truncate } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { cookies } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import collapsedLogo from '../../../assets/images/colLogo.png';
import logo from '../../../assets/images/logo.png';
import { BREAKPOINTS, KEY_ROLES, ROUTES } from '../../../common/constants';

const { Sider } = Layout;

function Sidebar({ userRole }) {
  const [collapsed, setCollapsed] = useState(false);
  const isLaptopViewPort = useMedia(`(min-width: ${BREAKPOINTS.laptop}px)`);
  const history = useHistory();
  const location = useLocation();
  const selectedItem = location?.pathname?.split('/')?.[1];
  const { state } = useContext(AppContext);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onMenuSelect = (e) => {
    history.push(e.key);
  };

  const GetTriggerIcon = () => {
    if (collapsed) {
      return <MenuUnfoldOutlined />;
    }
    return <MenuFoldOutlined />;
  };

  const items = [
    (userRole === KEY_ROLES?.ADMIN || userRole === KEY_ROLES?.SUPER_ADMIN) && {
      label: 'Dashboard',
      icon: <DashboardOutlined />,
      key: ROUTES.DASHBOARD,
      className: `d-flex align-center menu-list ${
        selectedItem === ROUTES.DASHBOARD ? 'ant-menu-item-selected' : ''
      }`
    },
    (userRole === KEY_ROLES?.ADMIN || userRole === KEY_ROLES?.SUPER_ADMIN) && {
      label: 'User Management',
      icon: <UserOutlined />,
      key: ROUTES.USER_MANAGEMENT,
      className: `d-flex align-center menu-list ${
        selectedItem === ROUTES.USER_MANAGEMENT ? 'ant-menu-item-selected' : ''
      }`
    },
    (userRole === KEY_ROLES?.ADMIN || userRole === KEY_ROLES?.SUPER_ADMIN) && {
      label: 'Export Users',
      icon: <UserOutlined />,
      key: ROUTES?.EXPORT_USERS,
      className: `d-flex align-center menu-list ${
        selectedItem === ROUTES?.EXPORT_USERS ? 'ant-menu-item-selected' : ''
      }`
    },
    {
      label: 'Moderation',
      icon: <FilterOutlined />,
      key: ROUTES.MODERATION,
      className: `d-flex align-center menu-list ${
        selectedItem === ROUTES.MODERATION ? 'ant-menu-item-selected' : ''
      }`
    },
    {
      label: (
        <div className="d-flex align-center">
          <Avatar
            src={state?.currentUser?.profileImage}
            className="d-flex align-center mr-5"
          >
            {get(state, 'currentUser.name', '')?.charAt(0)}
          </Avatar>
          <Tooltip title={get(state, 'currentUser.name', '')} placement="top">
            {!collapsed &&
              truncate(get(state, 'currentUser.name', ''), { length: 17 })}
          </Tooltip>
        </div>
      ),
      key: 'wc',
      children: [
        {
          label: 'Logout',
          key: 'logout',
          className: 'logout-submenu',
          onClick: () => {
            cookies.remove('accessToken');
            history.push(ROUTES.LOGOUT);
          }
        }
      ],
      className: `d-flex ${
        isLaptopViewPort ? 'bottom-8' : 'bottom-5'
      } position-absolute`
    }
  ];

  return (
    <Sider
      trigger={GetTriggerIcon()}
      collapsible
      collapsed={collapsed}
      onCollapse={toggle}
    >
      <div className="logo">
        {collapsed ? (
          <img src={collapsedLogo} alt="BTR" title="BTR" />
        ) : (
          <img src={logo} alt="Big_Time_Rush" title="Big_Time_Rush" />
        )}
      </div>

      <div className="gx-sidebar-content h-full">
        <Menu
          theme="lite"
          mode="vertical"
          selectedKeys={[`/${location?.pathname.split('/')[1]}`]}
          defaultSelectedKeys={
            userRole === KEY_ROLES?.ADMIN || userRole === KEY_ROLES?.SUPER_ADMIN
              ? [ROUTES.DASHBOARD]
              : [ROUTES.MODERATION]
          }
          onSelect={onMenuSelect}
          items={items}
          className="d-flex flex-vertical h-full"
        />
      </div>
    </Sider>
  );
}

export default Sidebar;
