import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const HIDE_ALL_COMMENTS_BY_USER = gql`
  mutation hideAllCommentsByPerson($where: HideAllCommentsByPersonInput!) {
    hideAllCommentsByPerson(where: $where) {
      message
    }
  }
`;
