import { gql } from '@apollo/client';

const GET_PROFILE_STATISTICS = gql`
  query profileStatistics($filter: ProfileStatisticsFilter) {
    profileStatistics(filter: $filter) {
      signUps {
        android
        ios
      }
      subscriptions {
        active {
          android
          ios
        }
        cancelled {
          android
          ios
        }
        expired {
          android
          ios
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_PROFILE_STATISTICS };
