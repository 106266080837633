import { useLazyQuery } from '@apollo/client';
import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { authClient } from '../apollo';
import { AppContext } from '../AppContext';
import LoaderComponent from '../components/LoaderComponent';
import RouterPrompt from '../components/RouterPrompt';
import './App.css';
import Sidebar from './components/sidebar/Sidebar';
import ContentRoutes from './ContentRoutes';
import { GET_CURRENT_PERSON } from './graphql/Queries';

const { Content } = Layout;

const App = () => {
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [fetchPerson] = useLazyQuery(GET_CURRENT_PERSON, {
    client: authClient,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setUserRole(res?.currentPerson?.role);
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.currentPerson
      });
      setLoading(false);
    }
  });

  useEffect(() => {
    try {
      fetchPerson();
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return loading ? (
    <LoaderComponent />
  ) : (
    state?.currentUser && (
      <Layout className="gx-app-layout">
        <RouterPrompt openPrompt={state?.showPrompt} />
        <Sidebar userRole={userRole} />
        <Layout>
          <Content className="gx-layout-content">
            <div className="gx-main-content-wrapper p-0">
              <ContentRoutes userRole={userRole} />
            </div>
          </Content>
        </Layout>
      </Layout>
    )
  );
};

export default App;
