import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const VALIDATE_TOKEN = gql`
  query validateToken($data: ValidateTokenInput!) {
    validateToken(data: $data) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshToken($refreshToken: String!) {
    refreshToken(data: { refreshToken: $refreshToken }) {
      accessToken
    }
  }
`;
