import { gql } from '@apollo/client';

const HIDE_POST = gql`
  mutation hidePost($postId: ID!, $data: HidePostData!) {
    hidePost(where: { id: $postId }, data: $data) {
      message
      data {
        id
      }
    }
  }
`;

const UPDATE_POST = gql`
  mutation updatePost($data: PostUpdateData!, $where: PostWhereUniqueInput!) {
    updatePost(where: $where, data: $data) {
      message
      data {
        id
        text
        image
        video {
          id
          thumbnailUrl
          videoUrl
          duration
          videoGif
        }
        mediaDimensions {
          height
          width
        }
      }
    }
  }
`;

const CREATE_POST_AS_AUTHOR = gql`
  mutation createPostAsAuthor($data: CreatePostAsAuthorData!) {
    createPostAsAuthor(data: $data) {
      message
      data {
        id
        text
        image
        video {
          id
          thumbnailUrl
          videoUrl
          duration
          videoGif
        }
        mediaDimensions {
          height
          width
        }
      }
    }
  }
`;

const UPDATE_COMMENT_MODERATION = gql`
  mutation updateCommentModeration(
    $where: CommentWhereUniqueInput!
    $data: UpdateCommentModerationData!
  ) {
    updateCommentModeration(where: $where, data: $data) {
      message
      data {
        id
      }
    }
  }
`;

export {
  HIDE_POST,
  UPDATE_COMMENT_MODERATION,
  UPDATE_POST,
  CREATE_POST_AS_AUTHOR
};
