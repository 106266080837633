import { LikeOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Popover, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { socialClient } from '../apollo';
import { AppContext } from '../AppContext';
import { ADMIN_ROLES } from '../common/constants';
import { LIKE_COMMENT_AS_AUTHOR } from './graphql/Mutation';
import { GET_AUTHOR_LIST } from './graphql/Queries';

const { confirm } = Modal;
const { Option } = Select;

const LikeCommentAsAuthor = (props) => {
  const { state } = useContext(AppContext);
  const { comment } = props;
  const [loading, setLoading] = useState(false);
  const { data: authorList } = useQuery(GET_AUTHOR_LIST, {
    client: socialClient,
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  const [likeCommentAsAuthor] = useMutation(LIKE_COMMENT_AS_AUTHOR, {
    client: socialClient
  });

  const handleLikeAsAuthor = async (authorId) => {
    setLoading(true);
    confirm({
      title: `Are you sure, you want to like this comment as ${
        authorList?.authors?.data?.find((author) => author?.id === authorId)
          ?.name
      }?`,
      centered: true,
      okText: 'Like',
      okType: 'primary',
      onCancel: () => {
        setLoading(false);
      },
      async onOk() {
        try {
          const variables = {
            where: { id: comment?.id },
            data: { action: 'LIKE', authorId }
          };
          const response = await likeCommentAsAuthor({
            variables: variables
          });
          if (response?.data?.likeCommentAsAuthor) {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    });
  };

  const content = (
    <Select
      onSelect={handleLikeAsAuthor}
      disabled={loading}
      placeholder="Select Author"
      allowClear
      className="mr-10"
    >
      {authorList?.authors?.data?.map((author) => (
        <Option key={author?.id} value={author?.id}>
          {author?.name}
        </Option>
      ))}
    </Select>
  );

  return (
    <>
      {ADMIN_ROLES?.includes(state?.currentUser?.role) ? (
        <Popover placement="top" content={content} trigger="click">
          <LikeOutlined />
        </Popover>
      ) : (
        <LikeOutlined />
      )}
    </>
  );
};

export default LikeCommentAsAuthor;
