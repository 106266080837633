import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import { GET_PERSON_EXPORT_JOBS } from '../graphql/Queries';

const ExportUsersTable = () => {
  const initialUserFilter = {
    skip: 0,
    limit: 10
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [page, setPage] = useState(1);
  const [fetchPersonExportJobs, { loading, data }] = useLazyQuery(
    GET_PERSON_EXPORT_JOBS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.personExportJobs?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchPersonExportJobs({ variables: { filter: { ...userFilter } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPage(current);
    setUserFilter({ ...userFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchPersonExportJobs({
      variables: {
        filter: {
          ...userFilter,
          skip,
          limit: pagination.pageSize
        }
      }
    });
  };

  const handleRefresh = () => {
    fetchPersonExportJobs();
  };

  const handleDownload = async (record) => {
    // eslint-disable-next-line no-undef
    window?.open(record?.fileUrl, '_self');
  };

  const renderActionButtons = (record) => {
    return (
      <div className="action-icons">
        {record?.fileUrl && (
          <DownloadOutlined
            disabled
            onClick={() => handleDownload(record)}
            twoToneColor="#f44336"
          />
        )}
      </div>
    );
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '2%',
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: 'Job Id',
      dataIndex: 'id',
      key: 'id',
      width: '22%',
      sorter: false
    },
    {
      title: 'Job Filter',
      dataIndex: 'filter',
      key: 'filter',
      width: '22%',
      render: (text) => {
        return (
          <div>
            {text?.roles && (
              <Tag className="mt-5" color="green">
                <Tooltip title="Role filter was applied" placement="top">
                  {text?.roles?.[0]}
                </Tooltip>
              </Tag>
            )}
            {text?.subscriptionPlans && (
              <Tag className="mt-5" color="red">
                <Tooltip
                  title="Subscription filter was applied"
                  placement="top"
                >
                  {text?.subscriptionPlans?.[0]}
                </Tooltip>
              </Tag>
            )}
            {text?.searchTerm && (
              <Tag className="mt-5" color="blue">
                <Tooltip title="Search filter was applied" placement="top">
                  {text?.searchTerm}
                </Tooltip>
              </Tag>
            )}
          </div>
        );
      }
    },
    {
      title: 'Job Status',
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      sorter: false
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '12%',
      render: (text) => moment(text).format(DATE_FORMAT)
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => {
        return renderActionButtons(record);
      }
    }
  ];

  return (
    <>
      <div className="d-flex justify-between align-center mb-10">
        <span>
          <strong>Note</strong>: The available downloadable links are valid for
          one day only from the date it was created. It won't be available
          afterward. You have to filter and re-export again.
        </span>
        <div>
          <Button onClick={handleRefresh} type="primary">
            Refresh
          </Button>
        </div>
      </div>
      <CommonTable
        loadingData={loading}
        columns={columns}
        data={data?.personExportJobs?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        tableClassName="user-table"
      />
    </>
  );
};

export default ExportUsersTable;
