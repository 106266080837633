import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row, Statistic, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { APPLE, DATE_TIME_FORMAT, GOOGLE } from '../../../common/constants';
import { GET_PROFILE_STATISTICS } from '../graphql/Queries';

const { RangePicker } = DatePicker;

function disabledDate(current) {
  return current && current > moment()?.endOf('day');
}

const DashboardTable = () => {
  const [dateRange, setDateRange] = useState(null);
  const [activeTabKey1, setActiveTabKey1] = useState('Active');
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  const initialDashboardFilter = {
    filter: {
      dateRange: {
        to: dateRange?.[1],
        from: dateRange?.[0]
      }
    }
  };
  const [dashboardFilter, setDashboardFilter] = useState(
    initialDashboardFilter
  );
  const [fetchUserData, { loading, data }] = useLazyQuery(
    GET_PROFILE_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  useEffect(() => {
    fetchUserData({ variables: { ...dashboardFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const handleDateRange = (range) => {
    if (range?.length > 1) {
      const day1 = range?.[0]?.startOf('day');
      const day2 = range?.[1]?.isSame(new Date(), 'day')
        ? range?.[1]
        : range?.[1]?.endOf('day');
      setDateRange([day1, day2]);
      setDashboardFilter({
        filter: {
          dateRange: {
            to: day2,
            from: day1
          }
        }
      });
      fetchUserData({ variables: { ...dashboardFilter } });
    } else {
      setDateRange(null);
      setDashboardFilter({
        filter: {
          dateRange: {
            to: '',
            from: ''
          }
        }
      });
      fetchUserData({ variables: { ...dashboardFilter } });
    }
  };

  const tabList = [
    {
      key: 'Active',
      tab: (
        <span>
          Active
          <Tooltip
            className="ml-5"
            placement="top"
            title="Newly activated subscription from the selected range"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      )
    },
    {
      key: 'Deleted',
      tab: (
        <span>
          Deleted
          <Tooltip
            className="ml-5"
            placement="top"
            title="Users are removed from the system and no longer exist"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      )
    },
    {
      key: 'Cancelled',
      tab: (
        <span>
          Cancelled
          <Tooltip
            className="ml-5"
            placement="top"
            title="Users have stopped using the app"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      )
    }
  ];
  const contentList = {
    Active: (
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title={GOOGLE}
            value={data?.profileStatistics?.subscriptions?.active?.android}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={APPLE}
            value={data?.profileStatistics?.subscriptions?.active?.ios}
          />
        </Col>
      </Row>
    ),
    Deleted: (
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title={GOOGLE}
            value={data?.profileStatistics?.subscriptions?.cancelled?.android}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={APPLE}
            value={data?.profileStatistics?.subscriptions?.cancelled?.ios}
          />
        </Col>
      </Row>
    ),
    Cancelled: (
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title={GOOGLE}
            value={data?.profileStatistics?.subscriptions?.expired?.android}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={APPLE}
            value={data?.profileStatistics?.subscriptions?.expired?.ios}
          />
        </Col>
      </Row>
    )
  };

  return (
    <div>
      <div className="d-flex mb-10">
        <RangePicker
          onChange={handleDateRange}
          disabledDate={disabledDate}
          showTime={{ format: 'HH:mm' }}
          format={DATE_TIME_FORMAT}
          className="mr-10"
        />
        <span className="d-flex align-center">
          Default is last 24 hours if no range is selected
        </span>
      </div>
      <div className="d-flex">
        <Card
          className="mr-10"
          title="Signups"
          bordered
          loading={loading}
          style={{
            width: '30%'
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={GOOGLE}
                value={data?.profileStatistics?.signUps?.android}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title={APPLE}
                value={data?.profileStatistics?.signUps?.ios}
              />
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            width: '70%'
          }}
          title="Subscriptions"
          loading={loading}
          tabList={tabList}
          activeTabKey={activeTabKey1}
          onTabChange={(key) => {
            onTab1Change(key);
          }}
        >
          {contentList[activeTabKey1]}
        </Card>
      </div>
    </div>
  );
};

export default DashboardTable;
