import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CURRENT_PERSON = gql`
  query currentPerson {
    currentPerson {
      id
      name
      profileImage
      firebaseId
      username
      isActive
      isBanned
      email
      role
      effectivePlan
      lastActiveOn
    }
  }
`;
