/* eslint-disable no-undef */
import {
  ControlOutlined,
  FlagOutlined,
  LinkOutlined,
  MessageOutlined,
  RollbackOutlined,
  StarFilled,
  UserOutlined
} from '@ant-design/icons';
import { Divider, Tooltip, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { truncate } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppContext } from '../../../../AppContext';
import { ADMIN_ROLES, GIPHY_URL } from '../../../../common/constants';
import { capitalizeWord } from '../../../../common/utils';
import AddCommentAsAuthor from '../../../../components/AddCommentAsAuthor';
import CommonCard from '../../../../components/CommonCard';
import LikeCommentAsAuthor from '../../../../components/LikeCommentAsAuthor';
import StarCommentAsAuthor from '../../../../components/StarCommentAsAuthor';
import CommonModerationButton from '../../comment/components/CommonModerationButtons';

const { Paragraph } = Typography;
const Comment = (props) => {
  const { state } = useContext(AppContext);
  const [showAddReply, setShowAddReply] = useState(false);
  const { data, handleActionButtons, handleCommentReplyShow, selectedPost } =
    props;

  const handleShowAddReply = () => {
    setShowAddReply(!showAddReply);
  };

  return (
    <div>
      <CommonCard className="mr-10 mb-10">
        <div className="d-flex justify-between align-center">
          <div className="d-flex align-center">
            <Avatar
              src={data?.createdBy?.profileImage}
              icon={<UserOutlined />}
              className="mr-10"
            />
            <h4 className="mb-0 mr-10 font-700">
              <Tooltip title={data?.createdBy?.name || ''} placement="top">
                {truncate(data?.createdBy?.name || '', { length: 17 })}
              </Tooltip>
            </h4>
            <p className="mb-0">
              <Tooltip
                title={`${moment(data?.createdAt)
                  .tz('America/Los_Angeles')
                  .format('Do MMMM, hh:mm A')} PST`}
              >
                {moment(data?.createdAt).tz('America/Los_Angeles').fromNow()}
              </Tooltip>
            </p>
          </div>
          <div>
            <ControlOutlined className="mr-5" />
            <span className="mr-10">
              {data?.moderationScore &&
                (data?.moderationScore * 100).toFixed(2)}
              %
            </span>
            <FlagOutlined /> {data?.flagsCount}
          </div>
        </div>
        <Paragraph className="mt-14">{data?.comment}</Paragraph>
        {data?.mediaUrl && (
          <div>
            <img src={data?.mediaUrl} alt="media" className="comment-media" />
          </div>
        )}
        {data?.giphyId && (
          <div>
            <img
              src={`${GIPHY_URL}/${data?.giphyId}/200.gif`}
              alt="giphy"
              className="comment-media"
            />
          </div>
        )}
        <div className="d-flex flex-horizontal align-center">
          {data?.stars?.length > 0 && (
            <div className="mr-10">
              <StarFilled className="mr-5 star-color" />
              <span className="star-wrapper mr-5">
                Starred by {data?.stars?.[0]?.name}
                {data?.stars?.length > 1 &&
                  ` and ${data?.starsCount - 1} other`}
              </span>
              {data?.stars?.slice(0, 3).map((person) => (
                <Avatar
                  key={person?.id}
                  size="small"
                  src={person?.profileImage}
                >
                  {person?.name?.charAt(0)}
                </Avatar>
              ))}
            </div>
          )}
          <div onClick={() => handleCommentReplyShow(data)}>
            <MessageOutlined className="pointer" />{' '}
            <span className="mr-10">{data?.replies?.count}</span>
          </div>
          {ADMIN_ROLES?.includes(state?.currentUser?.role) && (
            <div>
              <RollbackOutlined />{' '}
              <span className="pointer" onClick={handleShowAddReply}>
                Add Reply
              </span>
            </div>
          )}
        </div>
        {ADMIN_ROLES?.includes(state?.currentUser?.role) && showAddReply && (
          <div className="mt-10">
            <AddCommentAsAuthor
              postId={selectedPost}
              parentId={data?.id}
              setShowAddReply={setShowAddReply}
            />
          </div>
        )}
        <Divider className="divider" />
        <div>
          <LikeCommentAsAuthor comment={data} />
          <span className="mr-5"> {data?.likesCount} </span>
          <StarCommentAsAuthor comment={data} />{' '}
          <span className="mr-5"> {data?.starsCount} </span>
          <CopyToClipboard
            text={`${window?.location?.host}/moderation/comments/${
              data?.parentId ? data?.parentId : data?.id
            }`}
          >
            <Tooltip
              title="Copied!"
              trigger="click"
              onClick={(e) => e?.stopPropagation()}
            >
              <Tooltip title="Click to copy!" placement="bottom">
                <LinkOutlined className="mr-5 pointer" />
                <span className="pointer">Copy Link </span>
              </Tooltip>
            </Tooltip>
          </CopyToClipboard>
        </div>
        <div className="d-flex flex-horizontal justify-between mt-10">
          <ul>
            {data?.activityLogs?.map((log) => (
              <li className="activity-logs" key={log?.id}>{`${
                log?.triggeredByPerson &&
                capitalizeWord(log?.triggeredByPerson?.role)
              } ${log?.triggeredByPerson?.name}
              ${log?.title && capitalizeWord(log?.title)} ${
                log?.createdAt &&
                moment(log?.createdAt)
                  .tz('America/Los_Angeles')
                  .format('MM/DD/YYYY h:mm a')
              } `}</li>
            ))}
          </ul>
          <div className="d-flex flex-vertical width-percent-40 float-right">
            <CommonModerationButton
              handleActionButtons={handleActionButtons}
              comment={data}
            />
          </div>
        </div>
      </CommonCard>
    </div>
  );
};

export default Comment;
