import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, Modal, Popover, Select } from 'antd';
import React, { useState } from 'react';
import { socialClient } from '../apollo';
import { ADD_COMMENT_AS_AUTHOR } from './graphql/Mutation';
import { GET_AUTHOR_LIST } from './graphql/Queries';

const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

const AddCommentAsAuthor = (props) => {
  const { postId, parentId, isComment, setShowAddReply } = props;
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(null);
  const [sendBtnActive, setSendBtnActive] = useState(false);
  const { data: authorList } = useQuery(GET_AUTHOR_LIST, {
    client: socialClient,
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  const [addCommentAsAuthor] = useMutation(ADD_COMMENT_AS_AUTHOR, {
    client: socialClient
  });

  const handleComment = (e) => {
    setComment(e?.target?.value);
  };

  const handleSendButton = () => {
    setSendBtnActive(true);
  };

  const handleAddCommentAsAuthor = async (authorId) => {
    setLoading(true);
    confirm({
      title: `Are you sure, you want to add this comment as ${
        authorList?.authors?.data?.find((author) => author?.id === authorId)
          ?.name
      }?`,
      centered: true,
      okText: 'Add',
      okType: 'primary',
      onCancel: () => {
        setLoading(false);
        setComment(null);
        setSendBtnActive(false);
        if (!isComment) {
          setShowAddReply(false);
        }
      },
      async onOk() {
        try {
          const variables = {
            data: { postId, parentId, comment, authorId }
          };
          const response = await addCommentAsAuthor({
            variables: variables
          });
          if (response?.data?.createCommentAsAuthor) {
            setLoading(false);
            setComment(null);
            setSendBtnActive(false);
            if (!isComment) {
              setShowAddReply(false);
            }
          }
        } catch (error) {
          setLoading(false);
          setComment(null);
          setSendBtnActive(false);
          if (!isComment) {
            setShowAddReply(false);
          }
        }
      }
    });
  };

  const content = (
    <Select
      onSelect={handleAddCommentAsAuthor}
      disabled={loading}
      placeholder="Select Author"
      allowClear
      className="mr-10"
    >
      {authorList?.authors?.data?.map((author) => (
        <Option key={author?.id} value={author?.id}>
          {author?.name}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="d-flex">
      <div className="width-percent-90 mr-10">
        <TextArea
          cols={2}
          value={comment}
          placeholder="Write a comment and send..."
          onChange={handleComment}
        />
      </div>
      <div className="d-flex align-center">
        <Popover
          visible={sendBtnActive && comment}
          placement="top"
          content={content}
          trigger="click"
        >
          <Button
            id="btn-send-comment-submit"
            type="primary"
            htmlType="submit"
            disabled={!comment}
            loading={loading}
            onClick={handleSendButton}
          >
            Send
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default AddCommentAsAuthor;
