import { Card } from 'antd';
import React from 'react';
import ExportUsersTable from './components/ExportUsersTable';

const ExportUsers = () => {
  return (
    <Card className="m-12">
      <div className="d-flex align-center justify-between">
        <h1>Export Users</h1>
        <div id="user-export-btn" />
      </div>
      <hr />
      <ExportUsersTable />
    </Card>
  );
};

export default ExportUsers;
