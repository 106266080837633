import { gql } from '@apollo/client';

const GET_USERS = gql`
  query personList(
    $skip: Int!
    $limit: Int!
    $sort: PersonListSort
    $searchTerm: String
    $roleIn: [Role]
    $subscriptionPlans: [Plan]
  ) {
    personList(
      filter: {
        limit: $limit
        skip: $skip
        searchTerm: $searchTerm
        roles: $roleIn
        subscriptionPlans: $subscriptionPlans
      }
      sort: $sort
    ) {
      count
      data {
        id
        name
        email
        isActive
        isBanned
        lastActiveOn
        profileImage
        username
        effectivePlan
        subscriptionPlan
        promoSubscriptionPlan
        promoSubscriptionPlanExpiration
        role
      }
    }
  }
`;

const GET_PROFILE_IMAGE_SIGNED_URL = gql`
  query getPersonProfileImageSignedPutUrl(
    $data: PersonProfileImageSignedPutUrlDataInput!
  ) {
    getPersonProfileImageSignedPutUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

const GET_REQUEST_PERSON_EXPORT = gql`
  query requestPersonExport($filter: RequestPersonExportFilter) {
    requestPersonExport(filter: $filter) {
      jobId
      message
    }
  }
`;

export { GET_PROFILE_IMAGE_SIGNED_URL, GET_USERS, GET_REQUEST_PERSON_EXPORT };
