import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken, dispatch } = useContext(AppContext);
  const history = useHistory();
  const pathname = history?.location?.state?.pathname;
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, authRefreshToken) {
    initializeAuth(accessToken, authRefreshToken);
  }
  const { loading } = useQuery(REFRESH_TOKEN, {
    client: authClient,
    fetchPolicy: 'network-only',
    variables: { refreshToken: refreshToken },
    onCompleted(response) {
      const accessToken = get(response, 'refreshToken.accessToken');
      successCallback(accessToken, refreshToken);
      if (pathname) {
        history.push(pathname);
      } else {
        history.push(ROUTES.USER_MANAGEMENT);
      }
    },
    onError() {
      dispatch({ type: 'LOGOUT' });
    }
  });

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
