import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import VideoPlayer from './VideoPlayer';

const VideoPlayerModal = (props) => {
  const { data, showModal, handleCancel } = props;
  return (
    <Modal
      width="60%"
      height="40vh"
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
    >
      <VideoPlayer width="100%" height="100%" data={data} />
    </Modal>
  );
};

export default VideoPlayerModal;
