import {
  EyeInvisibleOutlined,
  MoreOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Divider, Popover, Tooltip, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { truncate } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { PERCENT_CONSTANT } from '../../../../common/constants';
import { capitalizeWord, convertToPercentage } from '../../../../common/utils';
import CommonCard from '../../../../components/CommonCard';
import RenderModerationTags from '../../component/RenderModerationTags';

const { Paragraph } = Typography;
const LiveComment = (props) => {
  const { data, showCommentHiddenConfirm, handleHideAllComment } = props;
  const filterModeration = data?.moderationResponse?.classes?.filter(
    (response) => convertToPercentage(response?.score) >= PERCENT_CONSTANT
  );
  const renderActionButtons = (
    <div className="d-flex flex-vertical">
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleHideAllComment(data?.createdBy);
        }}
      >
        Hide All Comments
      </Button>
    </div>
  );
  return (
    <div>
      <CommonCard className="mt-10 mr-10">
        <div className="d-flex justify-between">
          <div className="d-flex align-center">
            <Avatar
              src={data?.createdBy?.profileImage}
              icon={<UserOutlined />}
              className="mr-5"
            />
            <h4 className="mb-0 mr-5 font-700">
              <Tooltip title={data?.createdBy?.name} placement="top">
                {truncate(data?.createdBy?.name || '', { length: 17 })}
              </Tooltip>
            </h4>
            <span>
              <Tooltip
                title={`${moment(data?.createdAt)
                  .tz('America/Los_Angeles')
                  .format('Do MMMM, hh:mm A')} PST`}
              >
                {moment(data?.createdAt).tz('America/Los_Angeles').fromNow()}
              </Tooltip>
            </span>
          </div>
          <div>
            <Popover className="mr-10" content={renderActionButtons}>
              <MoreOutlined />
            </Popover>
            <Button
              icon={<EyeInvisibleOutlined />}
              className={
                data?.isHidden ? 'moderation-action-btn mt-10' : 'mt-10'
              }
              onClick={(e) => {
                e.stopPropagation();
                showCommentHiddenConfirm(data?.id, data?.isHidden);
              }}
            >
              Hidden
            </Button>
          </div>
        </div>
        <Paragraph className="mt-14">{data?.comment}</Paragraph>
        {filterModeration?.length > 0 && (
          <div>
            {filterModeration?.map((response) => (
              <RenderModerationTags key={response.class} data={response} />
            ))}
          </div>
        )}
        <Divider className="divider" />
        <div className="mt-10">
          <ul>
            {data?.activityLogs?.map((log) => (
              <li className="activity-logs" key={log?.id}>{`${
                log?.triggeredByPerson &&
                capitalizeWord(log?.triggeredByPerson?.role)
              } ${log?.triggeredByPerson?.name}
              ${log?.title && capitalizeWord(log?.title)} ${
                log?.createdAt &&
                moment(log?.createdAt)
                  .tz('America/Los_Angeles')
                  .format('MM/DD/YYYY h:mm a')
              } `}</li>
            ))}
          </ul>
        </div>
      </CommonCard>
    </div>
  );
};

export default LiveComment;
