import * as Sentry from '@sentry/react';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import App from './app/App';
import { ROUTES } from './common/constants';
import history from './historyData';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import PrivateRoute from './PrivateRoute';

const Routes = () => {
  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.LOGOUT} component={Logout} />
          <PrivateRoute
            path={ROUTES.UNAUTHENTICATED}
            component={RefreshToken}
          />
          <PrivateRoute path="/" component={App} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
