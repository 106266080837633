import Pusher from 'pusher-js';
import React from 'react';

const PusherContext = React.createContext();

function PusherProvider({ children }) {
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    encrypted: true,
    appId: process.env.REACT_APP_PUSHER_APP_ID,
    key: process.env.REACT_APP_PUSHER_KEY,
    secret: process.env.PUSHER_SECRET,
    useTLS: true,
    authEndpoint: `${process.env.REACT_APP_SOCIAL_FEED_REST_URL}/pusher/auth`
  });

  pusher.connection.bind('error', function (err) {
    if (err?.error?.data?.code === 4004) {
      // eslint-disable-next-line no-console
      console.log('Pusher Account limit Over!');
    }
  });

  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

// Create custom hook for using the Pusher Context
function usePusher() {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }

  const { pusher } = context;
  return pusher;
}

export { PusherProvider, usePusher };
