import { gql } from '@apollo/client';

const LIKE_COMMENT_AS_AUTHOR = gql`
  mutation likeCommentAsAuthor(
    $where: CommentWhereUniqueInput!
    $data: LikeCommentAsAuthorData!
  ) {
    likeCommentAsAuthor(where: $where, data: $data) {
      message
      data {
        id
        comment
      }
    }
  }
`;

const STAR_COMMENT_AS_AUTHOR = gql`
  mutation starCommentAsAuthor(
    $where: CommentWhereUniqueInput!
    $data: StarCommentAsAuthorData!
  ) {
    starCommentAsAuthor(where: $where, data: $data) {
      message
      data {
        id
        comment
      }
    }
  }
`;

const ADD_COMMENT_AS_AUTHOR = gql`
  mutation createCommentAsAuthor($data: CreateCommentAsAuthorData!) {
    createCommentAsAuthor(data: $data) {
      message
      data {
        id
        comment
      }
    }
  }
`;

export {
  ADD_COMMENT_AS_AUTHOR,
  LIKE_COMMENT_AS_AUTHOR,
  STAR_COMMENT_AS_AUTHOR
};
