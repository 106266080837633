import { gql } from '@apollo/client';

const UPDATE_PERSON = gql`
  mutation updatePerson(
    $personId: ID!
    $name: String
    $role: Role
    $promoSubscriptionPlan: Plan
    $promoSubscriptionPlanExpiration: DateTime
    $profileImage: String
    $username: String
  ) {
    updatePerson(
      where: { id: $personId }
      data: {
        name: $name
        role: $role
        promoSubscriptionPlan: $promoSubscriptionPlan
        promoSubscriptionPlanExpiration: $promoSubscriptionPlanExpiration
        profileImage: $profileImage
        username: $username
      }
    ) {
      message
      data {
        id
        name
        profileImage
        firebaseId
        username
        isActive
        isBanned
        email
        role
        effectivePlan
        promoSubscriptionPlan
        promoSubscriptionPlanExpiration
        lastActiveOn
      }
    }
  }
`;

const DEACTIVATE_PERSON = gql`
  mutation deactivatePerson($personId: ID!, $action: DeactivatePersonAction!) {
    deactivatePerson(where: { id: $personId }, data: { action: $action }) {
      message
      data {
        id
        isActive
      }
    }
  }
`;

const BAN_USER = gql`
  mutation banUser($where: PersonWhereUniqueInput!, $data: BanUserData!) {
    banUser(where: $where, data: $data) {
      message
      data {
        id
        name
      }
    }
  }
`;

export { BAN_USER, DEACTIVATE_PERSON, UPDATE_PERSON };
