import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Col, Empty, Modal, Row, Spin } from 'antd';
import { cloneDeep, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { socialClient } from '../../../../apollo';
import { PUSHER_EVENTS } from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { usePusher } from '../../../../pusher';
import { HIDE_POST } from '../graphql/Mutations';
import Post from './Post';

const limit = 10;
const { confirm } = Modal;
const PostList = (props) => {
  const {
    postForEdit,
    handleEditPost,
    setSelectedPost,
    selectedPost,
    handleShowPostComments,
    authorSearch,
    postSearch,
    dateRange,
    filterValue,
    sortOrder,
    isPostLoading,
    fetchMorePosts,
    postListData,
    setEditPostData,
    setShowPostForm
  } = props;
  const virtuoso = useRef(null);
  const pusher = usePusher();
  const [posts, _setPosts] = useState([]);
  const postsRef = useRef(posts);

  const [hidePost] = useMutation(HIDE_POST, {
    client: socialClient
  });
  const setPosts = (data) => {
    postsRef.current = data;
    _setPosts([...data]);
  };

  const postCreateHandler = async (data) => {
    if (data?.post?.node && !data?.post?.node?.isLive) {
      setPosts([data?.post?.node, ...postsRef?.current]);
    }
  };

  const postUpdateHandler = async (data) => {
    if (data?.post?.node) {
      const index = postsRef?.current?.findIndex(
        (post) => post?.id === data?.post?.node?.id
      );
      if (index !== -1) {
        const newPosts = cloneDeep(postsRef?.current);
        newPosts[index] = {
          ...data?.post?.node,
          referencePost: newPosts[index]?.referencePost
        };
        setPosts([...newPosts]);
      }
    }
  };

  useEffect(() => {
    const postChannel = pusher.subscribe(PUSHER_EVENTS.POST_EVENT);
    postChannel.bind('UPDATED', postUpdateHandler);
    postChannel.bind('CREATED', postCreateHandler);
    return () => {
      postChannel.unbind('CREATED', postCreateHandler);
      postChannel.unbind('UPDATED', postUpdateHandler);
      pusher.unsubscribe(PUSHER_EVENTS.POST_EVENT);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commentCreateHandler = (data) => {
    if (data?.comment?.node?.postId) {
      const index = postsRef?.current?.findIndex(
        (post) => post?.id === data?.comment?.node?.postId
      );
      if (index !== -1) {
        const newPosts = cloneDeep(postsRef?.current);
        newPosts[index] = {
          ...newPosts[index]
        };
        setPosts(newPosts);
      }
    }
  };

  useEffect(() => {
    const commentChannel = pusher.subscribe(
      PUSHER_EVENTS.COMMENT_MODERATION_EVENT
    );
    commentChannel.bind('CREATED', commentCreateHandler);
    return () => {
      commentChannel.unbind('CREATED', commentCreateHandler);
      pusher.unsubscribe(PUSHER_EVENTS.COMMENT_MODERATION_EVENT);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPostLoading && postListData?.listPostModeration) {
      const postList = postListData?.listPostModeration?.data;
      setPosts([...postList]);
      if (postList?.length > 0) {
        setSelectedPost(postList?.[0]?.id);
      } else {
        setSelectedPost(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostLoading]);

  const loadMorePosts = async () => {
    try {
      await fetchMorePosts({
        variables: {
          filter: {
            skip: posts?.length,
            limit: limit,
            authorId: authorSearch,
            searchTerm: postSearch,
            createdAt: {
              after: dateRange?.[0],
              before: dateRange?.[1]
            }
          },
          sort: {
            ...(filterValue && { sortOn: filterValue }),
            ...(sortOrder && { sortBy: sortOrder })
          }
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const { listPostModeration: newPostsData } = fetchMoreResult;
            setPosts(uniqBy([...posts, ...newPostsData?.data]), 'id');
          }
        }
      });
    } catch (error) {
      return error;
    }
  };

  const showPostHiddenConfirm = (postId, isHidden) => {
    confirm({
      title: `Are you sure, you want to ${
        isHidden ? 'unhide' : 'hide'
      } this post?`,
      okText: isHidden ? 'Unhide' : 'Hide',
      okType: isHidden ? 'primary' : 'danger',
      className: 'danger-btn',
      async onOk() {
        try {
          const variables = isHidden
            ? { data: { action: 'UNHIDE' }, postId }
            : { data: { action: 'HIDE' }, postId };
          const response = await hidePost({
            variables: variables
          });
          if (response?.data?.hidePost) {
            const newPosts = cloneDeep(posts);
            const id = newPosts?.findIndex(
              (post) => post?.id === response?.data?.hidePost?.data?.id
            );
            if (id !== -1) {
              newPosts[id].isDeleted = !isHidden;
            }
            setPosts(newPosts);
          }
        } catch (error) {
          return error;
        }
      }
    });
  };

  if (isPostLoading) {
    return <LoaderComponent />;
  }
  if (posts?.length === 0) {
    return <Empty />;
  }
  return (
    <div className="virtuoso mt-10">
      {isPostLoading && posts?.length === 0 ? (
        <LoaderComponent />
      ) : (
        <Virtuoso
          overscan={100}
          ref={virtuoso}
          data={posts}
          endReached={
            posts?.length < postListData?.listPostModeration?.count &&
            loadMorePosts
          }
          itemContent={(index, post) => {
            if (!post) {
              return null;
            }
            return (
              <Post
                index={index}
                key={post?.id}
                post={post}
                showPostHiddenConfirm={showPostHiddenConfirm}
                handleShowPostComments={handleShowPostComments}
                postForEdit={postForEdit}
                handleEditPost={handleEditPost}
                selectedPost={selectedPost}
                setEditPostData={setEditPostData}
                setShowPostForm={setShowPostForm}
              />
            );
          }}
          footer={() => {
            if (isPostLoading) {
              return (
                <Row className="d-flex justify-center">
                  <Col>
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 44 }} spin />
                      }
                    />
                  </Col>
                </Row>
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default PostList;
