import {
  CloseCircleOutlined,
  PlayCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as UpChunk from '@mux/upchunk';
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Select,
  Upload
} from 'antd';
import heic2any from 'heic2any';
import React, { useContext, useEffect, useState } from 'react';
import { socialClient, toast } from '../../../../apollo';
import { AppContext } from '../../../../AppContext';
import { fileUpload, formValidatorRules } from '../../../../common/utils';
import LoaderComponent from '../../../../components/LoaderComponent';
import VideoPlayerModal from '../../../../components/VideoPlayerModal';
import { CREATE_POST_AS_AUTHOR, UPDATE_POST } from '../graphql/Mutations';
import { GET_IMAGE_SIGNED_URL, GET_VIDEO_SIGNED_URL } from '../graphql/Queries';
import ReferenceData from './ReferenceData';

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;
const { required } = formValidatorRules;
const EditPost = (props) => {
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [hasMediaRemove, setHasMediaRemove] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [isUploadMedia, setIsUploadMedia] = useState(false);
  const [imageVideoProgress, setImageVideoProgress] = useState(undefined);
  const {
    editData,
    isPostLoading,
    handleShowPostComments,
    editLoader,
    authorList,
    isUpdate
  } = props;
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const { resetFields } = form;
  const [updatePost] = useMutation(UPDATE_POST, {
    client: socialClient
  });
  const [createPost] = useMutation(CREATE_POST_AS_AUTHOR, {
    client: socialClient
  });

  useEffect(() => {
    if (
      editData &&
      !editData?.image &&
      !editData?.video &&
      !editData?.referenceType
    ) {
      setHasMediaRemove(true);
    } else {
      setHasMediaRemove(false);
    }
    setIsUploadMedia(false);
  }, [editData]);

  const handleCancel = () => {
    setShowPlayerModal(false);
  };

  const handleModal = () => {
    setShowPlayerModal(true);
  };

  const handleRemoveFile = () => {
    setIsUploadMedia(false);
    setDeleteMedia(true);
  };

  const onPostSubmitFinish = async (values) => {
    setIsSubmit(true);
    setLoading(true);
    const { image, video } = values;
    try {
      const textData = {
        text: values?.text,
        authorId: values?.author
      };
      let imageUrl = null;
      let videoId = null;
      let imageUuid = null;
      let referenceType = editData?.referenceType;
      let referenceId = editData?.referenceId;
      if (image?.fileList?.length > 0) {
        setImageVideoProgress(0);
        let imagefile = image?.fileList?.[0]?.originFileObj;
        let fileName = image?.fileList?.[0]?.name;
        if (
          imagefile?.type?.toLowerCase() === 'image/heic' ||
          imagefile?.name?.toLowerCase()?.includes('.heic')
        ) {
          const ext = fileName
            ?.toLowerCase()
            ?.split('.')?.[1]
            .replace('heic', 'jpeg');
          const file = fileName?.split('.')?.[0];
          fileName = `${file}.${ext}`;
          imagefile = await heic2any({
            blob: imagefile,
            toType: 'image/jpeg',
            quality: 0.8
          });
          imageUrl = URL?.createObjectURL(imagefile);
        }
        const getSignedPutUrlResult = await socialClient.query({
          query: GET_IMAGE_SIGNED_URL,
          variables: { data: { fileName: fileName?.replace(/ /g, '_') } },
          fetchPolicy: 'network-only'
        });
        if (getSignedPutUrlResult?.data?.getPostImageUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getPostImageUploadSignedUrl?.signedUrl,
            imagefile,
            setImageVideoProgress
          );
          imageUrl =
            getSignedPutUrlResult?.data?.getPostImageUploadSignedUrl?.key;
          imageUuid =
            getSignedPutUrlResult?.data?.getPostImageUploadSignedUrl?.uuid;
        }
        if (hasMediaRemove) {
          referenceId = null;
          referenceType = null;
        }
        const media = {
          ...textData,
          image: imageUrl,
          imageUuid: imageUuid,
          videoId: videoId,
          referenceType: referenceType,
          referenceId: referenceId
        };
        if (!imageUrl && !imageUuid && !deleteMedia) {
          delete media?.image;
          delete media?.imageUuid;
        }
        if (!videoId && !deleteMedia) {
          delete media?.videoId;
        }
        if (isUpdate) {
          const response = await updatePost({
            variables: {
              where: { id: editData?.id },
              data: { ...media }
            }
          });
          if (response?.data?.updatePost) {
            resetFields();
            setLoading(false);
            setImageVideoProgress(undefined);
            setTimeout(() => {
              handleShowPostComments(editData?.id);
            }, 500);
          }
        } else {
          const response = await createPost({
            variables: {
              data: { ...media }
            }
          });
          if (response?.data?.createPostAsAuthor) {
            resetFields();
            setLoading(false);
            setImageVideoProgress(undefined);
            setTimeout(() => {
              handleShowPostComments(
                response?.data?.createPostAsAuthor?.data?.id
              );
            }, 500);
          }
        }
      } else if (video?.fileList?.length > 0) {
        setImageVideoProgress(0);
        const getSignedPutUrlResult = await socialClient.query({
          query: GET_VIDEO_SIGNED_URL,
          fetchPolicy: 'network-only'
        });
        const upload = UpChunk.createUpload({
          endpoint:
            getSignedPutUrlResult?.data?.getPostVideoUploadSignedUrl?.signedUrl,
          file: video?.file,
          chunkSize: 30720
        });
        toast({
          message: 'Video uploading is under process..',
          type: 'success',
          duration: 0
        });
        dispatch({
          type: 'SET_SHOW_PROMPT',
          data: true
        });
        videoId =
          getSignedPutUrlResult?.data?.getPostVideoUploadSignedUrl?.postVideoId;
        if (hasMediaRemove) {
          referenceId = null;
          referenceType = null;
        }
        const media = {
          ...textData,
          image: imageUrl,
          imageUuid: imageUuid,
          videoId: videoId,
          referenceType: referenceType,
          referenceId: referenceId
        };
        if (!videoId && !deleteMedia) {
          delete media?.videoId;
        }
        if (!imageUrl && !imageUuid && !deleteMedia) {
          delete media?.image;
          delete media?.imageUuid;
        }
        upload.on('error', (err) => {
          dispatch({
            type: 'SET_SHOW_PROMPT',
            data: false
          });
          message.destroy();
          if (err?.message) {
            toast({
              message: err?.message,
              type: 'error'
            });
          } else {
            toast({
              message: 'Something Went Wrong',
              type: 'error'
            });
          }
        });
        upload.on('progress', (progress) => {
          setImageVideoProgress(progress?.detail?.toFixed(0));
        });
        upload.on('success', async () => {
          if (isUpdate) {
            const response = await updatePost({
              variables: {
                where: { id: editData?.id },
                data: { ...media }
              }
            });
            if (response?.data?.updatePost) {
              resetFields();
              setLoading(false);
              setImageVideoProgress(undefined);
              dispatch({
                type: 'SET_SHOW_PROMPT',
                data: false
              });
              message.destroy();
              setTimeout(() => {
                handleShowPostComments(editData?.id);
              }, 500);
            }
          } else {
            const response = await createPost({
              variables: {
                data: { ...media }
              }
            });
            if (response?.data?.createPostAsAuthor) {
              resetFields();
              setLoading(false);
              setImageVideoProgress(undefined);
              dispatch({
                type: 'SET_SHOW_PROMPT',
                data: false
              });
              message.destroy();
              setTimeout(() => {
                handleShowPostComments(
                  response?.data?.createPostAsAuthor?.data?.id
                );
              }, 500);
            }
          }
        });
      } else {
        if (hasMediaRemove) {
          referenceId = null;
          referenceType = null;
        }
        const media = {
          ...textData,
          image: imageUrl,
          imageUuid: imageUuid,
          videoId: videoId,
          referenceType: referenceType,
          referenceId: referenceId
        };
        if (!imageUrl && !imageUuid && !deleteMedia) {
          delete media?.image;
          delete media?.imageUuid;
        }
        if (!videoId && !deleteMedia) {
          delete media?.videoId;
        }
        if (isUpdate) {
          const response = await updatePost({
            variables: {
              where: { id: editData?.id },
              data: { ...media }
            }
          });
          if (response?.data?.updatePost) {
            resetFields();
            setLoading(false);
            setImageVideoProgress(undefined);
            setTimeout(() => {
              handleShowPostComments(editData?.id);
            }, 500);
          }
        } else {
          const response = await createPost({
            variables: {
              data: { ...media }
            }
          });
          if (response?.data?.createPostAsAuthor) {
            resetFields();
            setLoading(false);
            setImageVideoProgress(undefined);
            setTimeout(() => {
              handleShowPostComments(
                response?.data?.createPostAsAuthor?.data?.id
              );
            }, 500);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setImageVideoProgress(undefined);
    }
  };

  const handleRemoveUpload = () => {
    confirm({
      title: `Are you sure, you want to remove ${
        editData?.image ? 'image' : 'video'
      }?`,
      okText: 'Remove',
      okType: 'danger',
      className: 'danger-btn',
      onOk() {
        setHasMediaRemove(true);
        setDeleteMedia(true);
      }
    });
  };

  const initialValues = {
    ...editData,
    image: null,
    video: null
  };

  return (
    <div>
      <h4 className="mb-0 mr-10 font-700">
        {isUpdate ? 'Edit Post' : 'Create Post'}
      </h4>
      <Divider />
      {isPostLoading || editLoader ? (
        <LoaderComponent />
      ) : (
        <>
          <Form
            form={form}
            initialValues={initialValues || { isActive: true }}
            layout="vertical"
            onFinish={onPostSubmitFinish}
            className="mt-10"
          >
            <Form.Item
              name="text"
              rules={[
                { required, message: 'Please enter the post description!' }
              ]}
            >
              <TextArea
                rows={3}
                placeholder="post description"
                disabled={isSubmit}
              />
            </Form.Item>
            {!hasMediaRemove && (editData?.video || editData?.image) && (
              <div className="media-container">
                {editData?.video?.videoUrl && (
                  <div className="video-container">
                    <VideoPlayerModal
                      data={editData}
                      showModal={showPlayerModal}
                      handleCancel={handleCancel}
                    />
                    <img src={editData?.video?.videoGif} alt="post" />
                  </div>
                )}
                {editData?.image && (
                  <div className="image-container">
                    <img src={editData?.image} alt="post" />
                  </div>
                )}
                <span className="remove-btn" onClick={handleRemoveUpload}>
                  <CloseCircleOutlined />
                </span>
                {(editData?.video?.videoGif ||
                  editData?.video?.thumbnailUrl) && (
                  <Button
                    icon={<PlayCircleOutlined style={{ fontSize: '44px' }} />}
                    shape="circle"
                    className="post-play-button"
                    size="large"
                    onClick={handleModal}
                  />
                )}
              </div>
            )}
            {!hasMediaRemove && editData?.referenceType && (
              <div className="media-container">
                <ReferenceData
                  type={editData?.referenceType}
                  post={editData?.referencePost}
                />
                <span className="remove-btn" onClick={handleRemoveUpload}>
                  <CloseCircleOutlined />
                </span>
              </div>
            )}
            <Form.Item name="image" label="Image:">
              <Upload
                maxCount={1}
                disabled={isSubmit}
                accept=".jpg,.jpeg,.png,.heic"
                onRemove={handleRemoveFile}
                onChange={(info) => {
                  if (info?.fileList?.length > 0) setIsUploadMedia(true);
                }}
                beforeUpload={() => {
                  return false;
                }}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={!hasMediaRemove || isUploadMedia}
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name="video" label="Video:">
              <Upload
                maxCount={1}
                accept=".mp4"
                onRemove={handleRemoveFile}
                disabled={isSubmit}
                onChange={(info) => {
                  if (info?.fileList?.length > 0) setIsUploadMedia(true);
                }}
                beforeUpload={() => {
                  return false;
                }}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={!hasMediaRemove || isUploadMedia}
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form.Item>
            <div>
              {imageVideoProgress >= 0 && (
                <Progress
                  percent={imageVideoProgress}
                  size="small"
                  status="active"
                />
              )}
            </div>
            {!isUpdate && (
              <Form.Item
                name="author"
                label="Author:"
                rules={[{ required, message: 'Please select the author!' }]}
              >
                <Select
                  placeholder="Select Author"
                  allowClear
                  disabled={isSubmit}
                >
                  {authorList?.authors?.data?.map((author) => (
                    <Option key={author?.id} value={author?.id}>
                      {author?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div className="d-flex justify-end mt-10">
              <Button
                id="btn-post-edit-cancel"
                onClick={() => handleShowPostComments(editData?.id)}
                className="mr-5"
                disabled={isSubmit}
              >
                Cancel
              </Button>
              <Button
                id="btn-post-edit-submit"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default EditPost;
