import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { KEY_ROLES, ROUTES } from '../common/constants';
import Error404 from '../Error404';
import DashboardWrapper from '../modules/dashboard';
import ExportUsersWrapper from '../modules/exportUsers';
import ModerationWrapper from '../modules/moderation';
import UsersWrapper from '../modules/userManagement';

const ContentRoutes = ({ userRole }) => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.USER_MANAGEMENT} component={UsersWrapper} />
        <Route path={ROUTES.EXPORT_USERS} component={ExportUsersWrapper} />
        <Route path={ROUTES.MODERATION} component={ModerationWrapper} />
        <Route path={ROUTES.DASHBOARD} component={DashboardWrapper} />
        {userRole === KEY_ROLES?.ADMIN ||
        userRole === KEY_ROLES?.SUPER_ADMIN ? (
          <Route
            exact
            path={ROUTES.MAIN}
            render={() => <Redirect to={ROUTES.DASHBOARD} />}
          />
        ) : (
          <Route
            exact
            path={ROUTES.MAIN}
            render={() => <Redirect to={ROUTES.MODERATION} />}
          />
        )}

        <Route exact path="*" component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
