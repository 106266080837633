import { gql } from '@apollo/client';

const LIST_POST_MODERATION = gql`
  query listPostModeration(
    $filter: ListPostModerationFilter
    $sort: ListPostModerationSort
  ) {
    listPostModeration(filter: $filter, sort: $sort) {
      count
      data {
        id
        text
        createdAt
        isLive
        isDeleted
        referenceType
        referenceId
        likesCount
        totalFlagsCount
        commentsCount
        playsCount
        liveStreamEndedAt
        retentionPeriod
        image
        video {
          id
          thumbnailUrl
          videoGif
          videoUrl
          duration
          status
          videoState
          streamKey
        }
        mediaDimensions {
          height
          width
        }
        isLiked
        author {
          id
          name
          profileImage
          username
        }
        createdAt
        referencePost {
          id
          text
          image
          author {
            id
            name
            profileImage
            username
          }
          createdAt
        }
      }
    }
  }
`;

const COMMENT_MODERATION = gql`
  query commentThreadModeration(
    $filter: CommentThreadModerationFilter!
    $sortBy: CommentThreadModerationSortBy
  ) {
    commentThreadModeration(filter: $filter, sortBy: $sortBy) {
      count
      data {
        id
        createdAt
        comment
        likesCount
        starsCount
        flagsCount
        parentId
        postId
        stars {
          id
          name
          profileImage
          username
        }
        giphyId
        mediaUrl
        mediaDimensions {
          height
          width
        }
        mediaType
        createdBy {
          id
          name
          profileImage
          username
        }
        createdAt
        replies {
          count
          data {
            id
            comment
            likesCount
            createdBy {
              id
            }
          }
        }
        activityLogs {
          id
          action
          title
          content
          createdAt
          triggeredByPerson {
            id
            name
            role
          }
        }
        isHidden
        isAccepted
        isDeleted
        isHighlighted
        moderationResponse
        moderationError
        moderationLabel
        moderationScore
        mediaModerationResponse
        mediaModerationError
      }
    }
  }
`;

const GET_AUTHOR_LIST = gql`
  query authors($filter: AuthorsFilter) {
    authors(filter: $filter) {
      count
      data {
        id
        name
        profileImage
        username
      }
    }
  }
`;

const GET_VIDEO_SIGNED_URL = gql`
  query getPostVideoUploadSignedUrl($data: PostVideoUploadSignedUrlData) {
    getPostVideoUploadSignedUrl(data: $data) {
      signedUrl
      postVideoId
    }
  }
`;

const GET_IMAGE_SIGNED_URL = gql`
  query getPostImageUploadSignedUrl($data: PostImageUploadSignedUrlData!) {
    getPostImageUploadSignedUrl(data: $data) {
      signedUrl
      key
      uuid
    }
  }
`;

export {
  GET_AUTHOR_LIST,
  LIST_POST_MODERATION,
  COMMENT_MODERATION,
  GET_VIDEO_SIGNED_URL,
  GET_IMAGE_SIGNED_URL
};
