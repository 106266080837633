import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_COMMENT = gql`
  mutation updateCommentModeration(
    $where: CommentWhereUniqueInput!
    $data: UpdateCommentModerationData!
  ) {
    updateCommentModeration(where: $where, data: $data) {
      message
      data {
        id
        comment
      }
    }
  }
`;
