import { LinkOutlined, MessageOutlined } from '@ant-design/icons';
import { Divider, Tag, Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { truncate } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { VIDEO_STATUS } from '../../../../common/constants';
import CommonCard from '../../../../components/CommonCard';

const LivePost = (props) => {
  const { post, selectedPost, setSelectedPost, setSelectedPostData } = props;
  const formatLiveDate = {
    sameDay: '[Today at] hh:mm A [went live]',
    lastDay: '[Yesterday at] hh:mm A [went live]',
    nextDay: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
    nextWeek: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
    lastWeek: 'MMM D,YYYY [at] hh:mm A [PST] [went live]',
    sameElse: 'MMM D,YYYY [at] hh:mm A [PST] [went live]'
  };

  return (
    <div
      className={`${
        selectedPost && selectedPost === post?.id
          ? 'selected-post mt-10 mr-5 border-radius-5'
          : 'mt-10 mr-5 border-radius-5'
      }`}
      onClick={() => {
        setSelectedPost(post?.id);
        setSelectedPostData(post);
      }}
    >
      <CommonCard>
        <div className="d-flex align-center">
          {post?.author && (
            <Avatar
              className="mr-5"
              size="default"
              src={post?.author?.profileImage || ''}
            >
              {post?.author?.name?.charAt(0) || ''}
            </Avatar>
          )}
          <div className="d-flex justify-between fill-width align-center">
            {post?.author && (
              <h4 className="mb-0 font-700">
                <Tooltip title={post?.author?.name} placement="top">
                  {truncate(post?.author?.name || '', { length: 17 })}
                </Tooltip>
              </h4>
            )}
            <div className="p-10">
              {post?.video?.status === VIDEO_STATUS.LIVE &&
                post?.video?.videoState === VIDEO_STATUS.RECORDING && (
                  <Tag color="#FF1F1F">Live</Tag>
                )}
              {post?.video?.videoState === VIDEO_STATUS.DISCONNECTED &&
                post?.createdAt &&
                moment(post?.createdAt)
                  .tz('America/Los_Angeles')
                  .calendar(null, formatLiveDate)}
            </div>
          </div>
        </div>
        <Divider className="divider" />
        <div className="d-flex justify-between">
          <div>
            <span className="mr-10">
              <MessageOutlined /> {post?.commentsCount}
            </span>
          </div>
          <div>
            <span>
              <CopyToClipboard
                // eslint-disable-next-line no-undef
                text={`${window?.location?.href}/${post?.id}`}
              >
                <Tooltip
                  title="Copied!"
                  trigger="click"
                  onClick={(e) => e?.stopPropagation()}
                >
                  <span>
                    <Tooltip title="Click to copy!" placement="bottom">
                      <LinkOutlined className="mr-5 pointer" />
                      <span className="pointer">Copy Link </span>
                    </Tooltip>
                  </span>
                </Tooltip>
              </CopyToClipboard>
            </span>
          </div>
        </div>
      </CommonCard>
    </div>
  );
};

export default LivePost;
