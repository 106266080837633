import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Live from './Live';

const LiveWrapper = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.MODERATION}/live`} component={Live} />
      <Route exact path={`${ROUTES.MODERATION}/live/:id`} component={Live} />
    </Switch>
  );
};

export default LiveWrapper;
