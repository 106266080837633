import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Comments from './Comments';

const CommentWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES.MODERATION}/comments`}
        component={Comments}
      />
      <Route
        exact
        path={`${ROUTES.MODERATION}/comments/:id`}
        component={Comments}
      />
    </Switch>
  );
};

export default CommentWrapper;
