import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_COMMENTS = gql`
  query listCommentsModeration(
    $filter: ListCommentsModerationFilter
    $sort: ListCommentModerationSort
  ) {
    listCommentsModeration(filter: $filter, sort: $sort) {
      count
      data {
        id
        likesCount
        starsCount
        flagsCount
        giphyId
        parentId
        comment
        mediaUrl
        mediaType
        isHidden
        createdBy {
          id
          name
          profileImage
        }
        isLiked
        isStared
        isFlaged
        isDeleted
        isAccepted
        isHighlighted
        replyCount
        replies {
          count
          data {
            id
            comment
          }
        }
        activityLogs {
          id
          action
          title
          content
          createdAt
          triggeredByPerson {
            id
            name
            role
          }
        }
        stars {
          id
          name
          profileImage
        }
        isStared
        mediaModerationError
        mediaModerationResponse
        moderationResponse
        moderationScore
        moderationLabel
        moderationError
        createdAt
        postId
      }
    }
  }
`;
