import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LOGOUT = gql`
  mutation logout {
    logout {
      message
    }
  }
`;
